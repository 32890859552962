import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { AccessGrantDto } from '../../common/models/access_grant_dto';

interface AccessGrantDataGridProps {
    grants: AccessGrantDto[];
    onUpdateGrant: (id: number, status: string) => void;
}

const AccessGrantDataGrid: React.FC<AccessGrantDataGridProps> = ({ grants: data, onUpdateGrant }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, rowId: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowId(rowId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleMenuItemClick = (status: string) => {
        if (selectedRowId !== null) {
            onUpdateGrant(selectedRowId, status);
        }
        handleMenuClose();
    };

    const columns: GridColDef[] = [
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'nodeId', headerName: 'Node ID', flex: 1 },
        { field: 'grantStatus', headerName: 'Grant Status', flex: 1 },
        {
            field: 'actions',
            headerName: '',
            align: 'center',
            flex: 1,
            renderCell: (params: GridRenderCellParams<AccessGrantDto>) => {
                const { grantStatus, id } = params.row;

                const menuOptions = (() => {
                    switch (grantStatus) {
                        case 'PENDING':
                            return [
                                { label: 'Grant as User', value: 'GRANTED_USER' },
                                { label: 'Grant as Node', value: 'GRANTED_NODE' },
                                { label: 'Deny as User', value: 'DENIED_USER' },
                                { label: 'Deny as Node', value: 'DENIED_NODE' },
                            ];
                        case 'GRANTED_USER':
                        case 'GRANTED_NODE':
                            return [
                                { label: 'Deny as User', value: 'DENIED_USER' },
                                { label: 'Deny as Node', value: 'DENIED_NODE' },
                                { label: 'Delete', value: 'DELETE' },
                            ];
                        case 'DENIED_USER':
                        case 'DENIED_NODE':
                            return [
                                { label: 'Grant as User', value: 'GRANTED_USER' },
                                { label: 'Grant as Node', value: 'GRANTED_NODE' },
                                { label: 'Delete', value: 'DELETE' },
                            ];
                        default:
                            return [];
                    }
                })();

                return (
                    <>
                        <Button endIcon={<ArrowDropDown />} onClick={(event) => handleMenuOpen(event, id)}>
                            Actions
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && selectedRowId === id}
                            onClose={handleMenuClose}
                        >
                            {menuOptions.map((option) => (
                                <MenuItem key={option.value} onClick={() => handleMenuItemClick(option.value)}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                );
            },
        },
    ];

    return (
        <div style={{ width: '100%', height: '100%'}}>
            <DataGrid rows={data} columns={columns} paginationMode="client"  hideFooterPagination/>
        </div>
    );
};

export default AccessGrantDataGrid;
