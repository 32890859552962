// useUserManager.ts
import { useEffect, useState } from 'react'
import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { AUTH_CALLBACK_ROUTE, HOME_ROUTE } from '../config/client_routes'
import { AUTH_CONFIG_URI_PATH } from '../services/query_service'
import { buildAuthorityUri } from '../services/auth_service'

// Base settings for UserManager
const BASE_SETTINGS: UserManagerSettings = {
    authority: '',
    client_id: '',
    response_type: 'code',
    scope: 'openid profile email',
    redirect_uri: '',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}

// Helper functions to create redirect URIs
export const createRedirectUri = () => `${window.location.origin}${AUTH_CALLBACK_ROUTE}`
export const createPostLogoutRedirectUri = () => `${window.location.origin}${HOME_ROUTE}`

/**
 * Custom React hook to fetch the OIDC configuration from the server and manage the UserManager instance.
 * 
 * This hook retrieves OIDC environment variables (keycloakHost, keycloakRealm, clientId)
 * from the `/api/connector/auth/config` endpoint, initializes a UserManager instance, and provides it to the component.
 * 
 * @returns {{
 *   authSettings: UserManagerSettings,
 *   userManager: UserManager | null,
 *   isUserManagerLoading: boolean,
 *   userManagerError: Error | null
 * }} An object containing the initialized UserManager instance, loading state, and any error encountered.
 */
export const useUserManager = () => {
    const [authSettings, setAuthSettings] = useState<UserManagerSettings>(BASE_SETTINGS)
    const [userManager, setUserManager] = useState<UserManager | null>(null)
    const [isUserManagerLoading, setIsUserManagerLoading] = useState<boolean>(true)
    const [userManagerError, setSetUserManagerError] = useState<Error | null>(null)
    const [dashboardUrl, setDashboardUrl] = useState<string>('')

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                setIsUserManagerLoading(true)
                const response = await fetch(AUTH_CONFIG_URI_PATH)

                if (!response.ok) {
                    throw new Error(`Failed to fetch config: ${response.statusText}`)
                }

                const data = await response.json()
                setDashboardUrl(data.grafanaDashboardUrl)

                const authSettings: UserManagerSettings = {
                    ...BASE_SETTINGS,
                    authority: buildAuthorityUri(data.keycloakHost, data.keycloakRealm),
                    client_id: data.clientId,
                    redirect_uri: createRedirectUri(),
                }

                // Initialize UserManager with fetched settings
                const manager = new UserManager(authSettings)
                console.log('created UserManager with settings:', authSettings)

                setAuthSettings(authSettings)
                setUserManager(manager)
            } catch (err) {
                if (err instanceof Error) {
                    setSetUserManagerError(err)
                } else {
                    setSetUserManagerError(new Error('An unknown error occurred while fetching OIDC config'))
                }
            } finally {
                setIsUserManagerLoading(false)
            }
        }

        fetchConfig()
    }, []) // Empty dependency array to ensure fetch runs only once on mount

    return { authSettings, userManager, isUserManagerLoading, userManagerError, dashboardUrl }
}
