import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { deleteLocalResourceSubscription, getLocalResourceSubscriptions } from "../services/query_service"

const queryKey = ['resources', 'subscription', 'send-to']

export const useSubscriptionQuery = () => {
    return useQuery({
        queryKey: queryKey,
        queryFn: async () => getLocalResourceSubscriptions(),
        staleTime: 1000 * 60 * 5, // 5 minutes
    })
}

export function useDeleteSubscription() {
    const queryClient = useQueryClient();

    return useMutation<void, Error, string>({
        mutationFn: (uuid) => deleteLocalResourceSubscription(uuid),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
            console.error('Error deleting subscription:', error);
        },
    });
}