import { useAccessGrantQuery } from "../common/hooks/use_access_grant_query"
import { useCommSummaryTotalsQuery } from "../common/hooks/use_comm_summary_totals_query"
import { useNodeSelfQuery } from "../common/hooks/use_node_self_query"
import { useResourceQuery } from "../common/hooks/use_resource_query"
import { AccessGrantDto } from "../common/models/access_grant_dto"
import { CommSummaryTotalsDto } from "../common/models/comm_summary_totals_dto"
import { NodeDto } from "../common/models/node_dto"
import { ResourceDto } from "../common/models/resource_dto"

export interface DashboardQueries {
    node: NodeDto | undefined
    resources: ResourceDto[] | undefined
    grants: AccessGrantDto[] | undefined
    commSummaryTotals: CommSummaryTotalsDto | undefined
    isPending: boolean
    isError: boolean
}

export function useDashboardQueries(): DashboardQueries {
    const { data: node, isPending: isNodePending, isError: isNodeError } = useNodeSelfQuery()
    const { data: resources, isPending: isResourceListPending, isError: isResourceListError } = useResourceQuery()
    const { data: grants, isPending: isGrantListPending, isError: isGrantListError } = useAccessGrantQuery()
    const { data: commSummaryTotals, isPending: isCommSummaryTotalsPending, isError: isCommSummaryTotalsError } = useCommSummaryTotalsQuery()

    const isPending = isNodePending || isResourceListPending || isGrantListPending || isCommSummaryTotalsPending
    const isError = isNodeError || isResourceListError || isGrantListError || isCommSummaryTotalsError

    return { node, resources, grants, commSummaryTotals, isPending, isError }
}
