import { Box } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ContentWithHeader from "../common/components/ContentWithHeader";
import { BusyMessage, ErrorMessage } from "../common/components/messages";
import CenterOnPage from "../common/layouts/CenterOnPage";
import SideNavLayout from "../common/layouts/SideNavLayout";
import { defaultNodeDto } from "../common/models/node_dto";
import { useSession } from "../common/providers/SessionContext";
import NodeActivityCard from "./components/NodeActivityCard";
import NodeInfoCard from "./components/NodeInfoCard";
import { useDashboardQueries } from "./use_dashboard_queries";
import { defaultCommSummaryTotals } from "../common/models/comm_summary_totals_dto";

const DashboardView = () => {
    const { dashboardUrl } = useSession()
    const { node, commSummaryTotals, isPending, isError } = useDashboardQueries()

    let content = null
    const safeNode = node || defaultNodeDto
    const safeCommTotals = commSummaryTotals || defaultCommSummaryTotals

    if (isPending) {
        content = (
            <CenterOnPage>
                <BusyMessage message="Loading Node Info..." />
            </CenterOnPage>
        )
    } else if (isError) {
        content = (
            <CenterOnPage>
                <ErrorMessage
                    type="Server Error"
                    message="Please contact your administrator to ensure you have access to this software"
                />
            </CenterOnPage>
        )
    } else {

        content = (
            <Box px={2} py={2}>
                <Grid container spacing={3} alignItems="stretch">
                    <NodeInfoCard node={safeNode} uptime={safeCommTotals.startTimestamp}/>
                    <NodeActivityCard dashboardUrl={dashboardUrl} commTotals={safeCommTotals} />
                </Grid>
            </Box>

        )
    }


    return (
        <SideNavLayout>
            <ContentWithHeader
                title="Home"
                breadcrumbs={[{ label: safeNode.name }]}
                children={content}
            />
        </SideNavLayout >
    )
}

export default DashboardView