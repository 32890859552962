import { Home, Inventory, Public, AccountCircle, Logout } from '@mui/icons-material'
import {
    Badge,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../assets/svg/connector_logo.svg'
import { HOME_ROUTE, LOCAL_RESOURCES_ROUTE, REMOTE_CATALOGS_ROUTE } from '../config/client_routes'
import { useSession } from '../providers/SessionContext'
import { buildUserAccountUri } from '../services/auth_service'
import { useAccessGrantQuery } from '../hooks/use_access_grant_query'
import { countPending } from '../models/access_grant_dto'

export interface NavigationItem {
    label: string,
    route: string,
    icon: ReactNode
    badgeContent: number
}

const navItems: NavigationItem[] = [
    { label: 'Home', route: HOME_ROUTE, icon: <Home />, badgeContent: 0 },
    { label: 'Local Resources', route: LOCAL_RESOURCES_ROUTE, icon: <Inventory />, badgeContent: 0 },
    { label: 'Remote Catalogs', route: REMOTE_CATALOGS_ROUTE, icon: <Public />, badgeContent: 0 }
]

function SideNavMenu() {
    const session = useSession()
    const location = useLocation()
    const navigate = useNavigate()
    const { data: grants } = useAccessGrantQuery()

    const selectedIdx = navItems.findLastIndex((item) => location.pathname.includes(item.route))
    const pendingGrants = countPending(grants)

    navItems[1].badgeContent = pendingGrants

    const handleAccountClick = () => {
        const { authority } = session.settings
        window.open(buildUserAccountUri(authority), '_blank', 'noopener,noreferrer');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Logo} alt='Logo' width='48' style={{ margin: "8px 2px" }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" noWrap component="span" sx={{ lineHeight: 1 }}>
                        Connector
                    </Typography>
                    <Typography variant="caption" color="textSecondary" noWrap component="span" sx={{ lineHeight: 1 }}>
                        v{process.env.REACT_APP_VERSION}
                    </Typography>
                </div>
            </div>

            <List>
                {navItems.map((it, index) => (
                    <ListItemButton key={it.label} onClick={() => navigate(it.route)} selected={index === selectedIdx}>
                        <ListItemIcon>
                            <Badge variant="dot" color="error" badgeContent={it.badgeContent} >
                                {it.icon}
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={it.label} />
                    </ListItemButton>
                ))}
            </List>

            <div style={{ flexGrow: 1 }} />

            <List>
                <ListItemButton key="user" selected={false} onClick={handleAccountClick}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </ListItemButton>
                <ListItemButton key="logout" selected={false} onClick={session.logout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItemButton>
            </List>
        </div>
    )
}

export default SideNavMenu