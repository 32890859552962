import { Box, Paper, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { BusyMessage, ErrorMessage } from "../common/components/messages"
import { LOCAL_RESOURCES_ROUTE } from "../common/config/client_routes"
import { useDeleteAccessGrant, useUpdateAccessGrant } from "../common/hooks/use_access_grant_query"
import CenterOnPage from "../common/layouts/CenterOnPage"
import SideNavLayout from "../common/layouts/SideNavLayout"
import { GrantStatus } from "../common/models/access_grant_dto"
import { titleCase } from "../common/utils/string_fns"
import AccessGrantDataGrid from "./common/AccessGrantDataGrid"
import { buildLocalResourceRoute } from "./common/local_resource_routes"
import { useLocalResourceQueries } from "./common/use_local_resource_grant_queries"

interface LocalResourceViewParams {
  [param: string]: string | undefined
}

const AccessGrantView = () => {
  const { resourceId } = useParams<LocalResourceViewParams>()
  const { resources, grants, isPending, isError } = useLocalResourceQueries()
  const updateMutation = useUpdateAccessGrant()
  const deleteMutation = useDeleteAccessGrant()

  let content = null
  const resource = resources?.find(r => r.uuid === resourceId)

  if (isPending) {
    content = (
      <CenterOnPage>
        <BusyMessage message="Loading Resources..." />
      </CenterOnPage>
    )
  } else if (isError) {
    content = (
      <CenterOnPage>
        <ErrorMessage type="Network Error" message="Could not retrieve the resource list." />
      </CenterOnPage>
    )
  } else {
    const handleGrantUpdate = (id: number, status: string) => {
      if (status === 'DELETE') {
        deleteMutation.mutate(id);
      } else {
        updateMutation.mutate({ id, grantStatus: status as GrantStatus })
      }
    }

    content = (
      <Box px={4} py={2} height="100%">
        <Paper sx={{ height: "100%" }}>
          <Stack height="100%" flexDirection="column" px={4} py={2}>
            <Typography variant="h5">Access Grants</Typography>
            <Box height={8} />
            <AccessGrantDataGrid
              grants={grants || []}
              onUpdateGrant={handleGrantUpdate}
            />
          </Stack>
        </Paper>
      </Box>
    )
  }

  const breadcrumbs = [
    { label: 'Local Resources', href: LOCAL_RESOURCES_ROUTE },
    { label: titleCase(resource?.name || ''), href: buildLocalResourceRoute(resourceId || '') },
    { label: 'Access Grants' }
  ]

  return (
    <SideNavLayout>
      <ContentWithHeader
        title="View Access Grants"
        breadcrumbs={breadcrumbs}
        children={content}
      />
    </SideNavLayout>
  )
}

export default AccessGrantView