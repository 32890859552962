import { Box } from "@mui/material"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { CenteredBusyMessage, CenteredErrorMessage } from "../common/components/messages"
import { useNodeQuery } from "../common/hooks/use_node_query"
import { useNodeSelfQuery } from "../common/hooks/use_node_self_query"
import SideNavLayout from "../common/layouts/SideNavLayout"
import NodeList from "./components/NodeList"

const NodeListView = () => {
    const { data: self, isPending: isSelfPending, isError: isSelfError } = useNodeSelfQuery()
    const { data: nodes, isPending: isNodeListPending, isError: isNodeListError } = useNodeQuery()

    let content = null

    if (isSelfPending || isNodeListPending) {
        content = <CenteredBusyMessage message="Fetching network information..." />
    } else if (isSelfError || isNodeListError) {
        content = <CenteredErrorMessage type="Network Error" message="Could not retrieve the list of nodes." />
    } else {
        const remoteNodes = nodes.filter(n => n.nodeId !== self?.nodeId)
        content = (
            <Box px={4} py={2}>
                <NodeList nodes={remoteNodes} />
            </Box>
        )
    }

    return (
        <SideNavLayout>
            <ContentWithHeader
                title="Resource Catalogs"
                children={content}
            />
        </SideNavLayout>
    )
}

export default NodeListView