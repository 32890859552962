import { Outlet, Route, Routes } from 'react-router-dom'
import NodeListView from './NodeListView'
import RemoteCatalogView from './RemoteCatalogView'
import ResourceDetailsView from './ResourceDetailsView'
import { NotFoundError } from '../common/components/messages'

export const RemoteCatalogsPage = () => {
    return (
        <div>
            <Routes>
                <Route path={''} element={<NodeListView />} />
                <Route path={':nodeId'} element={<RemoteCatalogView />} />
                <Route path={':nodeId/:resourceId'} element={<ResourceDetailsView />} />
                <Route path="*" element={<NotFoundError message={`Page not found.`} />} />
            </Routes>

            <Outlet />
        </div >
    )
}
