import { useSession } from '../common/providers/SessionContext';
import DashboardView from './DashboardView';
import NotAuthenticatedView from './NotAuthenticatedView';

function HomePage() {
    const { isAuthenticated } = useSession()
    return isAuthenticated ? <DashboardView /> : <NotAuthenticatedView/>
}

export default HomePage;
