import { GrantStatus } from "./access_grant_dto";

/**
 * Represents metadata for a resource in the federated data network.
 *
 * Fields:
 * - description: A brief description of the resource.
 * - example: An example value or use case for the resource.
 * - name: The name of the resource.
 * - permissions: The access level of the resource, either 'PUBLIC' or 'RESTRICTED'.
 * - resourcePath: The path to access the resource.
 * - schemaUrl: The URL pointing to the resource's schema.
 * - type: The type of the resource, either 'DATA' or 'SERVICE'.
 * - uuid: A unique identifier for the resource.
 * - version: The version number of the resource.
 */
export interface ResourceDto {
    description: string;
    exampleRequest: string;
    exampleResponse: string;
    defaultRequestBody: string;
    name: string;
    permissions: 'PUBLIC' | 'RESTRICTED';
    grantStatus?: GrantStatus;
    resourcePath: string;
    schemaUrl: string;
    type: 'DATA' | 'SERVICE';
    uuid: string;
    version: number;
}

export const defaultResourceDto: ResourceDto = {
    name: '',
    description: '',
    exampleRequest: '',
    exampleResponse: '',
    defaultRequestBody: '',
    permissions: 'PUBLIC',
    grantStatus: undefined,
    resourcePath: '',
    schemaUrl: '',
    type: 'DATA',
    uuid: '',
    version: 1,
}

export function isResourcePublic(res: ResourceDto): boolean {
    switch (res.permissions) {
        case 'PUBLIC':
            return true;
        case 'RESTRICTED':
            return false;
    }
}
