import { useQuery } from "@tanstack/react-query"
import { getResourceCatalogForNode } from "../services/query_service"

export function getResourceCatalogQueryKeyForNode(nodeId: string) {
    return (['data_exchange', 'catalog', nodeId])
}

export const useResourceCatalogQuery = (nodeId: string) => {
    return useQuery({
        queryKey: getResourceCatalogQueryKeyForNode(nodeId),
        queryFn: async () => getResourceCatalogForNode(nodeId),
        staleTime: 1000 * 60 * 5, // 5 minutes
    })
}
