import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import ContentWithHeader from '../common/components/ContentWithHeader';
import { BusyMessage, ErrorMessage, NotFoundError } from '../common/components/messages';
import { LOCAL_RESOURCES_ROUTE } from '../common/config/client_routes';
import CenterOnPage from '../common/layouts/CenterOnPage';
import SideNavLayout from '../common/layouts/SideNavLayout';
import { groupGrantsByResource } from '../common/models/access_grant_dto';
import { titleCase } from '../common/utils/string_fns';
import { useLocalResourceQueries } from './common/use_local_resource_grant_queries';
import LocalResourceDetails from './common/LocalResourceDetails';

interface LocalResourceViewParams {
  [param: string]: string | undefined
}

const LocalResourceView: React.FC = () => {
  // Extract the parameters from the URL
  const { resourceId } = useParams<LocalResourceViewParams>()
  const { resources, grants, subscriptions, isPending, isError } = useLocalResourceQueries()

  let content = null
  const resource = resources?.find(r => r.uuid === resourceId)

  if (isPending) {
    content = (
      <CenterOnPage>
        <BusyMessage message="Loading Resources..." />
      </CenterOnPage>
    )
  } else if (isError) {
    content = (
      <CenterOnPage>
        <ErrorMessage type="Network Error" message="Could not retrieve the resource." />
      </CenterOnPage>
    )
  } else if (!resource) {
    content = <NotFoundError message={`Resource ID "${resourceId}" was not found.`} />
  } else {
    const localGrants = groupGrantsByResource(grants || []).get(resourceId!)
    const localSubscriptions = subscriptions?.filter(sub=>sub.resourceUuid === resourceId)

    content = (
      <Box px={4} py={2}>
        <LocalResourceDetails resource={resource} grants={localGrants || []} subscriptions={localSubscriptions || []}/>
      </Box>
    )
  }

  const resourceName = titleCase(resource?.name || 'Not Found')

  const breadcrumbs = [
    { label: `Local Resources`, href: LOCAL_RESOURCES_ROUTE },
    { label: resourceName },
  ]

  return (
    <SideNavLayout>
      <ContentWithHeader
        title={resourceName}
        breadcrumbs={breadcrumbs}
        children={content}
      />
    </SideNavLayout>
  )
}

export default LocalResourceView;
