/**
 * The response to a token exchange request from the server.  
 * 
 * We supply the server with the authorization code from the OAuth server, and our server will exchange
 * it for a JWT that we can then use to access resources on our server.
 * 
 * @interface TokenDto
 * @property {string} access_token - The JWT access token to be sent back to the server in every request.
 * @property {number} expires_in - The duration (in seconds) for which the token is valid.
 * @property {string} token_type - The type of token issued (e.g., Bearer).
 */
export interface TokenDto {
    access_token: string;
    expires_in: number;
    token_type: string;
}

/**
 * Default token value, used as an initial placeholder.
 * 
 * @constant
 * @type {TokenDto}
 */
export const defaultTokenDto: TokenDto = {
    access_token: '',
    expires_in: 0,
    token_type: ''
}
