import { useEffect, useState } from "react";

/**
 * Custom React hook to persist a state variable in localStorage.
 * 
 * This hook synchronizes a state value with localStorage. It retrieves the initial value from localStorage,
 * and updates the stored value whenever the state changes.
 * 
 * @param {string} key - The key in localStorage under which the value is stored.
 * @param {any} initialValue - The initial value to use if the key is not present in localStorage.
 * 
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} A tuple containing the current state value and a setter function to update the value.
 */
export function useLocalStorage<T>(key: string, initialValue: T): readonly [T, Function] {
    // Get the initial value from local storage if it exists
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error('Error reading from local storage:', error);
            return initialValue;
        }
    });

    // Update local storage whenever the stored value changes
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.error('Error writing to local storage:', error);
        }
    }, [key, storedValue]);

    return [storedValue, setStoredValue] as const;
}
