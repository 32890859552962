import Box from '@mui/material/Box'
import React from 'react'
import TitleWithEditButton from '../../local_resources_page/common/TitleWithEditButton'
import Typography from '@mui/material/Typography'
import MarkdownViewer from './MarkdownViewer'
import { getDocStringForType, getTitleStringForType, ResourceDocType } from '../../local_resources_page/common/EditDocString'
import { ResourceDto } from '../models/resource_dto'

interface ResourceDocsSectionProps {
    docType: ResourceDocType
    resource: ResourceDto
    onEdit?: () => void
}

const ResourceDocsSection = ({ docType, resource, onEdit }: ResourceDocsSectionProps) => {
    const title = getTitleStringForType(docType)
    const content = getDocStringForType(docType, resource)

    return (
        <Box px={2} py={2} marginBottom={3}>
            <TitleWithEditButton onEdit={onEdit}>
                <Typography variant="h5">{title}</Typography>
            </TitleWithEditButton>
            <MarkdownViewer content={content || ''} />
        </Box>
    )
}

export default ResourceDocsSection