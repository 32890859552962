import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { useNavigate, useParams } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { BusyMessage, ErrorMessage, NotFoundError } from "../common/components/messages"
import { LOCAL_RESOURCES_ROUTE } from "../common/config/client_routes"
import { useResourceQuery, useUpdateResource } from "../common/hooks/use_resource_query"
import CenterOnPage from "../common/layouts/CenterOnPage"
import SideNavLayout from "../common/layouts/SideNavLayout"
import { ResourceDto } from "../common/models/resource_dto"
import { titleCase } from "../common/utils/string_fns"
import { buildLocalResourceRoute } from "./common/local_resource_routes"
import ResourceForm from "./common/ResourceForm"

interface LocalResourceViewParams {
    [param: string]: string | undefined
}

const EditResourceView = () => {
    // Extract the parameters from the URL
    const navigate = useNavigate()
    const mutation = useUpdateResource();
    const { resourceId } = useParams<LocalResourceViewParams>()

    const { data: resources, isPending: isResourceListPending, isError: isResourceListError } = useResourceQuery()

    let content = null
    const resource = resources?.find(r => r.uuid === resourceId)
    const resourceRoute = buildLocalResourceRoute(resourceId || '')

    if (isResourceListPending) {
        content = (
            <CenterOnPage>
                <BusyMessage message="Loading Resources..." />
            </CenterOnPage>
        )
    } else if (isResourceListError) {
        content = (
            <CenterOnPage>
                <ErrorMessage type="Network Error" message="Could not retrieve the resource." />
            </CenterOnPage>
        )
    } else if (!resource) {
        content = <NotFoundError message={`Resource ID "${resourceId}" was not found.`} />
    } else {
        const handleCancel = () => navigate(resourceRoute)

        const handleSubmit = (r: ResourceDto) => {
            mutation.mutate(r, { onSuccess: () => navigate(resourceRoute) });
        };

        content = (
            <Box px={4} py={2} height="100%">
                <Paper sx={{ height: "100%", minHeight: 740 }}>
                    <ResourceForm
                        title="Edit Resource"
                        defaultValues={resource}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        isSaving={mutation.isPending}
                    />
                </Paper>
            </Box>
        )
    }

    const resourceName = titleCase(resource?.name || 'Not Found')

    const breadcrumbs = [
        { label: 'Local Resources', href: LOCAL_RESOURCES_ROUTE },
        { label: resourceName, href: resourceRoute },
        { label: 'Edit Resource' }
    ]

    return (
        <SideNavLayout>
            <ContentWithHeader
                title={resourceName}
                breadcrumbs={breadcrumbs}
                children={content}
            />
        </SideNavLayout>
    )
}

export default EditResourceView