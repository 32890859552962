import { Box, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ResourceDto } from '../../common/models/resource_dto';
import CancelSaveButtonRow from './CancelSaveButtonRow';

interface ResourceFormProps {
    title: string
    defaultValues: ResourceDto
    isSaving: boolean
    onCancel: () => void
    onSubmit: (r: ResourceDto) => void
}

const ResourceForm: React.FC<ResourceFormProps> = ({ title, defaultValues, onSubmit, onCancel, isSaving }) => {
    const { control, handleSubmit } = useForm<ResourceDto>({ defaultValues });

    const callSubmit = (data: Partial<ResourceDto>) => onSubmit({ ...defaultValues, ...data })

    return (
        <Box component="form" onSubmit={handleSubmit(callSubmit)} px={2} py={2} maxWidth={600} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5">{title}</Typography>
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Name" variant="outlined" fullWidth required autoFocus/>
                )}
            />

            <Controller
                name="description"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Description" variant="outlined" fullWidth multiline />
                )}
            />

            <Controller
                name="resourcePath"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Resource Path" variant="outlined" fullWidth required />
                )}
            />

            <Controller
                name="schemaUrl"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Schema URL" variant="outlined" fullWidth />
                )}
            />

            <Controller
                name="version"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Version"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                    />
                )}
            />

            <Controller
                name="permissions"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Permissions" select variant="outlined" fullWidth required>
                        <MenuItem value="PUBLIC">PUBLIC</MenuItem>
                        <MenuItem value="RESTRICTED">RESTRICTED</MenuItem>
                    </TextField>
                )}
            />

            <Controller
                name="type"
                control={control}
                render={({ field }) => (
                    <TextField {...field} label="Type" select variant="outlined" fullWidth required>
                        <MenuItem value="DATA">DATA</MenuItem>
                        <MenuItem value="SERVICE">SERVICE</MenuItem>
                    </TextField>
                )}
            />

            <CancelSaveButtonRow onCancel={onCancel} isSubmit={true} isSaving={isSaving} />
        </Box>
    );
};

export default ResourceForm;
