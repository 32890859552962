import { Typography } from "@mui/material"
import FlexColumn from "../layouts/FlexColumn"
import { ConnectorBusySpinner } from "./ConnectorBusySpinner"
import CenterOnPage from "../layouts/CenterOnPage"

interface MessageProps {
    type?: string
    message: string
}

export const ErrorMessage = ({ type, message }: MessageProps) => (
    <FlexColumn>
        <Typography variant="h4" color="error">
            {type}
        </Typography>
        <Typography variant="h5" color="error">
            {message}
        </Typography>
    </FlexColumn>
)

export const BusyMessage = ({ message }: MessageProps) => (
    <ConnectorBusySpinner label={message} />
)

export const CenteredBusyMessage = ({ message }: MessageProps) => (
    <CenterOnPage>
        <BusyMessage message={message} />
    </CenterOnPage>
)

export const CenteredErrorMessage = ({ type, message }: MessageProps) => (
    <CenterOnPage>
        <ErrorMessage type={type} message={message} />
    </CenterOnPage>
)

export const NotFoundError = ({ message }: MessageProps) => (
    <CenterOnPage>
        <ErrorMessage type="404 - Not Found" message={message} />
    </CenterOnPage>
)

