/**
 * Represents a list of access grants in the federated data network.
 *
 * Fields:
 * - grantStatus: The status of the grant for the resource (e.g., 'GRANTED_USER').
 * - id: A unique identifier for the grant entry.
 * - nodeId: The unique identifier of the node for which access is granted.
 * - resourceUuid: The unique identifier of the resource for which access is granted.
 * - username: The name of the user receiving the access grant.
 */
export interface AccessGrantDto {
  grantStatus: GrantStatus;
  id: number;
  nodeId: string;
  resourceUuid: string;
  username: string;
}

export type GrantStatus = 'GRANTED_USER' | 'GRANTED_NODE' | 'PENDING' | 'DENIED_USER' | 'DENIED_NODE' | 'PUBLIC' | 'RESTRICTED'

export const isAccessGranted = (grantStatus?: GrantStatus) => grantStatus && (grantStatus === 'GRANTED_NODE' || grantStatus === 'GRANTED_USER')

export function isGrantStatusInDefaultState(grantStatus: GrantStatus): boolean {
  switch (grantStatus) {
      case 'PUBLIC':
      case 'RESTRICTED':
          return true;
      default:
          return false;
  }
}

/**
 * Groups an array of AccessGrantDto objects by their nodeId property.
 *
 * @param grants - An array of AccessGrantDto objects to be grouped.
 * @returns A Map where each key is a nodeId, and each value is an array of AccessGrantDto objects with that nodeId.
 */
export function groupGrantsByNodeId(grants: AccessGrantDto[]): Map<string, AccessGrantDto[]> {
  return grants.reduce((map, grant) => {
    const key = grant.nodeId;

    if (!map.has(key)) {
      map.set(key, []);
    }

    map.get(key)!.push(grant);
    return map;
  }, new Map<string, AccessGrantDto[]>());
}

/**
 * Groups an array of AccessGrantDto objects by their resourceUuid property.
 *
 * @param grants - An array of AccessGrantDto objects to be grouped.
 * @returns A Map where each key is a resourceUuid, and each value is an array of AccessGrantDto objects with that resourceUuid.
 */
export function groupGrantsByResource(grants: AccessGrantDto[]): Map<string, AccessGrantDto[]> {
  return grants.reduce((map, grant) => {
    const key = grant.resourceUuid;
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key)!.push(grant);
    return map;
  }, new Map<string, AccessGrantDto[]>());
}

/**
 * Counts the number of AccessGrantDto objects with a grantStatus of 'PENDING'.
 *
 * @param grants - An array of AccessGrantDto objects, or undefined.
 * @returns The count of grants with a grantStatus of 'PENDING'. Returns 0 if the array is undefined.
 */
export function countPending(grants: AccessGrantDto[] | undefined): number {
  const filtered = grants?.filter(g => g.grantStatus === 'PENDING') || []
  return filtered.length
}