import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import "easymde/dist/easymde.min.css"; // Import SimpleMDE CSS
import MarkdownEditor from "./MarkdownEditor"
import { ResourceDto } from "../../common/models/resource_dto";
import { buildLocalResourceRoute } from "./local_resource_routes";
import { useNavigate } from "react-router-dom";
import { useUpdateResource } from "../../common/hooks/use_resource_query";

export type ResourceDocType = 'exampleRequest' | 'exampleResponse' | 'defaultRequestBody'

export const isExampleRequestDocType = (type: ResourceDocType) => type === 'exampleRequest'

export const getDocStringForType = (type: ResourceDocType, resource: ResourceDto) => {
    switch (type) {
        case 'exampleRequest':
            return resource.exampleRequest
        case 'exampleResponse':
            return resource.exampleResponse
        case 'defaultRequestBody':
            return resource.defaultRequestBody
    }
}

export const getTitleStringForType = (type: ResourceDocType) => {
    switch (type) {
        case 'exampleRequest':
            return 'Request Parameters'
        case 'exampleResponse':
            return 'Response Data'
        case 'defaultRequestBody':
            return 'Default Request Parameters'
    }
}

interface EditDocStringProps {
    title: string
    docType: ResourceDocType
    resource: ResourceDto
}

const EditDocString = ({ title, docType, resource }: EditDocStringProps) => {
    const navigate = useNavigate()
    const mutation = useUpdateResource()

    const resourceRoute = buildLocalResourceRoute(resource.uuid)
    const docString = getDocStringForType(docType, resource)

    const handleCancel = () => navigate(resourceRoute)

    const handleSave = (docString: string) => {
        const updatedResource = { ...resource, [docType]: docString }
        mutation.mutate(updatedResource, {
            onSuccess: () => navigate(resourceRoute),
        });
    }

    return (
        <Box px={4} py={2} height="100%">
            <Paper sx={{ height: "100%", minHeight: 740 }}>
                <Stack px={2} py={1} spacing={1} useFlexGap sx={{ height: "100%" }}>
                    <Typography variant="h5">Edit {title}</Typography>
                    <MarkdownEditor content={docString} onCancel={handleCancel} onSave={handleSave} isSaving={mutation.isPending} />
                </Stack>
            </Paper>
        </Box>
    )
}

export default EditDocString
