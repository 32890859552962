import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import LocalResource from '../assets/svg/local_resource.svg';
import RemoteResource from '../assets/svg/remote_resource.svg';
import { buildRoute } from '../config/client_routes';
import { AccessGrantDto, countPending, groupGrantsByResource } from '../models/access_grant_dto';
import { ResourceDto } from '../models/resource_dto';
import { safeDescription, titleCase } from '../utils/string_fns';
import HoverLink from './HoverLink';
import ResourceChipRow from './ResourceChipRow';
import ResourceUuid from './ResourceUuid';

export interface ResourceCatalogProps {
    resources: ResourceDto[] | undefined
    grants?: AccessGrantDto[] | undefined
    isLocal?: boolean
    baseUrl?: string
}


/**
 * Convenience function to create a local resource catalog component. 
 */
export const LocalResourceCatalog = (props: ResourceCatalogProps) => <ResourceCatalog {...props} isLocal={true} />

/**
 * Convenience function to create a remote resource catalog component. 
 */
export const RemoteResourceCatalog = (props: ResourceCatalogProps) => <ResourceCatalog {...props} isLocal={false} />

/**
 * Generalized resource catalog that can render both local or remote catalogs.
 */
const ResourceCatalog = ({ resources, grants, baseUrl, isLocal = true }: ResourceCatalogProps) => {
    if (!resources) {
        return null
    }

    if (resources.length === 0) {
        return <FallbackMessage />
    }

    const sortedResources = [...resources].sort((a, b) => a.name.localeCompare(b.name))
    const grantsByResource = groupGrantsByResource(grants || [])
    const url = baseUrl || ''

    return (
        <Grid container spacing={3}>
            {sortedResources.map((r, index) => (
                <Grid size={{ xs: 12, md: 6, xl: 4 }} key={index} style={{ display: 'flex' }}>
                    <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <ResourceCardHeader name={r.name} uuid={r.uuid} baseUrl={url} isLocal={isLocal} grants={grantsByResource.get(r.uuid)} />
                        <CardContent sx={{ paddingLeft: 4, paddingRight: 4, flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ flex: 1 }}>
                                {safeDescription(r.description)}
                            </Typography>
                            <ResourceChipRow resource={r} />
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

interface ResourceCardHeaderProps {
    name: string
    uuid: string
    baseUrl: string
    isLocal: boolean
    grants: AccessGrantDto[] | undefined
}

const ResourceCardHeader = ({ name, uuid, baseUrl, isLocal, grants }: ResourceCardHeaderProps) => {
    const icon = isLocal ? LocalResource : RemoteResource

    const avatar = (
        <RouterLink to={buildRoute(baseUrl, uuid)}>
            <Badge badgeContent={countPending(grants)} color="error" overlap="circular">
                <Avatar src={icon} alt="Resource Icon" sx={{ width: 75, height: 75 }} />
            </Badge>
        </RouterLink>
    )

    const title = (
        <HoverLink to={buildRoute(baseUrl, uuid)}>
            {titleCase(name)}
        </HoverLink>
    )

    return (
        <CardHeader
            avatar={avatar}
            title={title}
            titleTypographyProps={{ variant: 'h5' }}
            subheader={<ResourceUuid uuid={uuid} />}
            subheaderTypographyProps={{ variant: "caption", color: "textSecondary" }}
        />
    )
}

const FallbackMessage = () => (
    <Typography variant='h5'>This node does not yet provide any resources.</Typography>
)

export default ResourceCatalog;
