import { ReactNode } from "react"

interface CenterOnPageProps {
    children: ReactNode
}

const CenterOnPage = ({ children }: CenterOnPageProps) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {children}
        </div>
    )
}

export default CenterOnPage