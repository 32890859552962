import { InfoRounded } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import ResourceDocsSection from '../../common/components/ResourceDocsSection'
import ResourceUuid from '../../common/components/ResourceUuid'
import { isResourcePublic, ResourceDto } from '../../common/models/resource_dto'
import { safeDescription, titleCase } from '../../common/utils/string_fns'
import DataRequestPane from './DataRequestPane'
import RequestAccessButton from './RequestAccessButton'

interface ResourceDetailsProps {
    resource: ResourceDto
    name: string
    nodeId: string
}

export const ResourceDetails = ({ resource, name, nodeId }: ResourceDetailsProps) => {
    // Just toggle it back and forth to force a refresh -- is there a better way in React?
    const [refreshTrigger, setRefreshTrigger] = useState(false)
    
    const handleRefresh = () => setRefreshTrigger(!refreshTrigger)

    return (
        <Box px={2} py={3}>
            <Paper elevation={1}>
                <Box px={1} py={1}>
                    <Typography variant="h5">{name} </Typography>
                    <ResourceUuid uuid={resource.uuid} />

                    <ResourceDetailItem
                        text={safeDescription(resource?.description)}
                        label='Description'
                    />

                    <ResourceDetailItem
                        text={resource.version.toString()}
                        label='Version'
                    />

                    <ResourceDetailItem
                        text={titleCase(resource.type)}
                        label='Type'
                        caption='Data resources reply with a one-time response. Service resources allow you to subscribe for multiple updates.'
                    />

                    <ResourceDetailItem
                        text={titleCase(resource.permissions)}
                        label='Permission'
                        caption='Resources can either be Public or Restricted.  Users must be granted access to Restricted resources.'
                    />

                    {!isResourcePublic(resource) && resource.grantStatus &&
                        <Box sx={{ display: 'flex', justifyContent: "start", alignItems: "end" }}>
                            <ResourceDetailItem
                                text={titleCase(resource.grantStatus)}
                                label='Grant Status'
                                caption='The status of any access you have been granted to this resource.'
                            />
                            <RequestAccessButton nodeId={nodeId} resourceUuid={resource.uuid} grantStatus={resource.grantStatus} onRefreshNeeded={handleRefresh} />
                        </Box>
                    }

                    <div style={{ marginTop: 16 }} />

                    <Divider />
                    <ResourceDocsSection docType={'exampleRequest'} resource={resource} />

                    <Divider />
                    <ResourceDocsSection docType={'defaultRequestBody'} resource={resource} />

                    <Divider />
                    <ResourceDocsSection docType={'exampleResponse'} resource={resource} />

                    {resource.type === 'DATA' && <DataRequestPane nodeId={nodeId} resource={resource} />}
                </Box>
            </Paper>
        </Box>
    )
}

interface ResourceDetailItemProps {
    text: string
    label?: string
    caption?: string
}

const tightSpacing = { margin: 0, lineHeight: 1, marginRight: 1 };

const ResourceDetailItem = ({ text, label, caption }: ResourceDetailItemProps) => (
    <Box mt={1} mb={1}>
        {label && <Typography variant='caption' color='textSecondary'>{label}</Typography>}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h6' sx={tightSpacing}>{text}</Typography>
            {caption &&
                <Tooltip title={caption}>
                    <InfoRounded color='primary' sx={{ width: 20 }} />
                </Tooltip>
            }
        </Box>
    </Box>
)

