import {
    Box,
    CssBaseline,
    Drawer
} from '@mui/material'

import { ReactNode, useState } from 'react'
import SideNavMenu from './SideNavMenu'
import { contentBackgroundColor } from '../config/colors'

const drawerWidth = 240

const contentStyle = {
    flexGrow: 1,
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: contentBackgroundColor,
}

interface SideNavLayoutProps {
    children: ReactNode,
}

function SideNavLayout({ children }: SideNavLayoutProps) {
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CssBaseline />
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <SideNavMenu />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <SideNavMenu />
                </Drawer>
            </Box>
            <Box component="main" sx={contentStyle} >
                {children}
            </Box>
        </Box>
    )
}

export default SideNavLayout