/**
 * This modules contains utilty functions for working with strings.
 */

export type MaybeString = (string | undefined | null)

export const safeDescription = (description: MaybeString) => description || 'No description available.';

export const titleCase = (str: string): string =>
    str.split(/[\s_-]+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

export function dumbPluralize(count: number, noun: string): string {
    if (count === 1) {
        return `1 ${noun}`;
    } else {
        return `${count} ${noun}s`;
    }
}
