import { InfoRounded } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface LabledItemProps {
    text: string
    label?: string
    caption?: string
}

const tightSpacing = { margin: 0, lineHeight: 1, marginRight: 1 };

const LabledItem = ({ text, label, caption }: LabledItemProps) => (
    <Box mt={0} mb={1}>
        {label && <Typography variant='caption' color='textSecondary'>{label}</Typography>}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={tightSpacing}>{text}</Typography>
            {caption &&
                <Tooltip title={caption}>
                    <InfoRounded color='primary' sx={{ width: 20 }} />
                </Tooltip>
            }
        </Box>
    </Box>
)

export default LabledItem