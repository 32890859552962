import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AccessGrantDto, GrantStatus } from "../models/access_grant_dto"
import { deleteLocalResourceAccessInfo, getLocalResourceAccessInfo, updateLocalResourceAccessInfo } from "../services/query_service"

const queryKey = ['resources', 'access']

export const useAccessGrantQuery = () => {
    return useQuery({
        queryKey: queryKey,
        queryFn: async () => getLocalResourceAccessInfo(),
        staleTime: 1000 * 60 * 5, // 5 minutes
    })
}

interface AccessGrantUpdateParams {
    id: number;
    grantStatus: GrantStatus;
}

export function useUpdateAccessGrant() {
    const queryClient = useQueryClient();

    return useMutation<AccessGrantDto, Error, AccessGrantUpdateParams>({
        mutationFn: ({ id, grantStatus }) => updateLocalResourceAccessInfo(id, grantStatus),
        
        onSuccess: () => {
            // Invalidate any queries that may be affected by this update
            queryClient.invalidateQueries({ queryKey });
        },
        
        onError: (error) => {
            console.error('Error updating access:', error);
        },
    });
}

export function useDeleteAccessGrant() {
    const queryClient = useQueryClient();
    
    return useMutation<void, Error, number>({
        mutationFn: ( id ) => deleteLocalResourceAccessInfo(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
            console.error('Error deleting access:', error);
        },
    });
}