import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import NodeIcon from '../../common/assets/svg/node.svg';
import HoverLink from '../../common/components/HoverLink';
import { buildRemoteCatalogRoute } from '../../common/config/client_routes';
import { NodeDto } from '../../common/models/node_dto';
import { safeDescription, titleCase } from '../../common/utils/string_fns';

interface NodeListProps {
    nodes: NodeDto[]
}

const NodeList = ({ nodes }: NodeListProps) => {
    if (nodes.length === 0) {
        return <FallbackMessage />
    }

    const sortedNodes = [...nodes].sort((a, b) => a.name.localeCompare(b.name))

    return (
        <div style={{ height: '80vh', overflowY: 'auto', maxWidth: 1200 }}>
            <Grid container spacing={3} >
                {sortedNodes.map((node, index) => (
                    <Grid size={{ sm: 12, md: 6 }} key={index} style={{ display: 'flex' }}>
                        <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <NodeCardHeader name={node.name} uuid={node.nodeId} />
                            <CardContent sx={{ paddingLeft: 4, paddingRight: 4, flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" sx={{ flex: 1 }}>
                                    {safeDescription(node.description)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

interface NodeCardHeaderProps {
    name: string
    uuid: string
}

const NodeCardHeader = ({ name, uuid }: NodeCardHeaderProps) => {
    const avatar = (
        <RouterLink to={buildRemoteCatalogRoute(uuid)}>
            <Badge badgeContent={0} color="error" overlap="circular">
                <Avatar src={NodeIcon} alt="Resource Icon" sx={{ width: 75, height: 75 }} />
            </Badge>
        </RouterLink>
    )

    const title = (
        <HoverLink to={buildRemoteCatalogRoute(uuid)}>
            {titleCase(name)}
        </HoverLink>
    )

    return (
        <CardHeader
            avatar={avatar}
            title={title}
            titleTypographyProps={{ variant: 'h5' }}
            subheader={uuid}
            subheaderTypographyProps={{ variant: "caption", color: "textSecondary" }}
        />
    )
}

const FallbackMessage = () => (
    <Typography variant='h5'>There are currently no nodes in the network!</Typography>
)

export default NodeList;
