import styled from "@mui/material/styles/styled";
import { Link as RouterLink} from "react-router-dom"

const HoverLink = styled(RouterLink)({
    color: 'inherit', 
    textDecoration: 'none', // No underline by default
    '&:hover': {
        textDecoration: 'underline', // Underline on hover
    },
});

export default HoverLink