import { ReactNode } from "react"

interface FlexColumnProps {
    children: ReactNode
}

const FlexColumn = ({ children }: FlexColumnProps) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {children}
        </div>
    )
}

export default FlexColumn