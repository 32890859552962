import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const altContent = `
### No Documentation Provided
_Click the edit icon in the upper right of this section to add documenation_
`

interface MarkdownViewerProps {
    content?: string
}

export const MarkdownViewer = ({ content }: MarkdownViewerProps) => {
    
    return (
        <Box px={1} py={1} bgcolor="#F4F4F4" borderRadius={2}>
            <ReactMarkdown>{content || altContent}</ReactMarkdown>
        </Box>
    );
};

export default MarkdownViewer;
