import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { useNavigate } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { LOCAL_RESOURCES_ROUTE } from "../common/config/client_routes"
import { useCreateResource } from "../common/hooks/use_resource_query"
import SideNavLayout from "../common/layouts/SideNavLayout"
import { defaultResourceDto, ResourceDto } from "../common/models/resource_dto"
import ResourceForm from "./common/ResourceForm"

const AddResourceView = () => {
    const navigate = useNavigate()
    const mutation = useCreateResource();

    const handleCancel = () => navigate(LOCAL_RESOURCES_ROUTE)

    const handleSubmit = (r: ResourceDto) => {
        mutation.mutate(r, { onSuccess: () => navigate(LOCAL_RESOURCES_ROUTE) })
    }

    const content = (
        <Box px={4} py={2} height="100%">
            <Paper sx={{ height: "100%", minHeight: 740 }}>
                <ResourceForm
                    title="Add Resource"
                    defaultValues={defaultResourceDto}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    isSaving={mutation.isPending}
                />
            </Paper>
        </Box>
    )

    const breadcrumbs = [
        { label: 'Local Resources', href: LOCAL_RESOURCES_ROUTE },
        { label: 'Add Resource' }
    ]

    return (
        <SideNavLayout>
            <ContentWithHeader
                title="Add Resource"
                breadcrumbs={breadcrumbs}
                children={content}
            />
        </SideNavLayout>
    )
}

export default AddResourceView