import React from 'react'
import DashboardGridCard from './DashboardGridCard'
import LabledItem from './LabeledItem'
import NodeIcon from '../../common/assets/svg/node.svg';
import { NodeDto } from '../../common/models/node_dto';
import { formatUptime } from '../../common/utils/date_time_fns';

interface NodeInfoCardProps {
    node: NodeDto
    uptime: number
}

const NodeInfoCard = ({ node, uptime }: NodeInfoCardProps) => {
    console.log('uptime', uptime)
    return (
        <DashboardGridCard key="NodeInfo" icon={NodeIcon} title="Node Information">
            <LabledItem text={node.name} label="Node Name" />
            <LabledItem text={node.nodeId} label="Node ID" />
            <LabledItem text={formatUptime(uptime)} label="Uptime" />
            <LabledItem text={node.host} label="Host Name" />
        </DashboardGridCard>
    )
}

export default NodeInfoCard