import { Outlet, Route, Routes } from 'react-router-dom'
import { NotFoundError } from '../common/components/messages'
import AccessGrantView from './AccessGrantView'
import { ACCESS_ROUTE, ADD_RESOURCE_ROUTE, EDIT_REQUEST_PARAMS_ROUTE, EDIT_REQUEST_ROUTE, EDIT_RESOURCE_ROUTE, EDIT_RESPONSE_ROUTE, SUBSCRIPITION_ROUTE } from './common/local_resource_routes'
import EditResourceView from './EditResourceView'
import EditResourceDocsView from './EditResourceDocsView'
import LocalCatalogView from "./LocalCatalogView"
import LocalResourceView from './LocalResourceView'
import SubscriptionView from './SubscriptionView'
import AddResourceView from './AddResourceView'

export const LocalCatalogsPage = () => {
    return (
        <div>
            <Routes>
                <Route path={''} element={<LocalCatalogView />} />
                <Route path={`${ADD_RESOURCE_ROUTE}`} element={<AddResourceView />} />
                <Route path={':resourceId'} element={<LocalResourceView />} />
                <Route path={`:resourceId/${EDIT_RESOURCE_ROUTE}`} element={<EditResourceView />} />
                <Route path={`:resourceId/${EDIT_REQUEST_ROUTE}`} element={<EditResourceDocsView docType='exampleRequest'/>} />
                <Route path={`:resourceId/${EDIT_REQUEST_PARAMS_ROUTE}`} element={<EditResourceDocsView docType='defaultRequestBody'/>} />
                <Route path={`:resourceId/${EDIT_RESPONSE_ROUTE}`} element={<EditResourceDocsView docType='exampleResponse'/>} />
                <Route path={`:resourceId/${ACCESS_ROUTE}`} element={<AccessGrantView />} />
                <Route path={`:resourceId/${SUBSCRIPITION_ROUTE}`} element={<SubscriptionView />} />
                <Route path="*" element={<NotFoundError message={`Page not found.`} />} />
            </Routes>

            <Outlet />
        </div >
    )
}

export default LocalCatalogsPage