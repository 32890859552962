import SearchIcon from '@mui/icons-material/Search';
import { Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { contentBackgroundColor } from '../config/colors';
import HeaderActionButton, { HeaderActionButtonProps } from './HeaderActionButton';

const SearchBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey,
  marginRight: theme.spacing(2),
}));

interface BreadcrumbItem {
  label: string
  href?: string
}

type ActionButtonItem = HeaderActionButtonProps

// interface ActionButtonItem {
//   label: string
//   icon: ReactNode
//   onClick: () => void
// }

interface ContentHeaderProps {
  title: string
  onSearch?: (term: string) => void
  breadcrumbs?: BreadcrumbItem[]
  actions?: ActionButtonItem[]
  children: ReactNode
}

const ContentWithHeader = ({ title, onSearch, breadcrumbs, actions, children }: ContentHeaderProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%' }}>

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Typography variant="h5" color="inherit" noWrap>
              {title}
            </Typography>
          </Box>
          {onSearch ? (
            <SearchBox>
              <SearchIcon />
              <InputBase placeholder="Search" sx={{ ml: 1, flex: 1 }} />
            </SearchBox>
          ) : null}
        </Toolbar>

        <Divider />
        <BreadcrumbList breadcrumbs={breadcrumbs} actions={actions} />
      </AppBar>

      <div style={{ flexGrow: 1, overflow: 'auto' }}>
        {children}
      </div>
    </div>
  );
};

interface BreadcrumbListProps {
  breadcrumbs?: BreadcrumbItem[]
  actions?: ActionButtonItem[]
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#26C",
  textDecoration: "none",
  '&:hover': {
    textDecoration: "underline",
  }
}))

export const BreadcrumbList = ({ breadcrumbs, actions }: BreadcrumbListProps) => {
  if (!breadcrumbs && !actions) {
    return null
  }

  return (
    <>
      <Box bgcolor={contentBackgroundColor} py={1} px={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb" separator="❯" sx={{ marginLeft: 1 }}>
          {breadcrumbs && breadcrumbs.map((it) =>
            it.href
              ? <StyledLink key={it.href} to={it.href}>{it.label}</StyledLink>
              : <Typography key={it.label} color="textPrimary">{it.label}</Typography>

          )}
        </Breadcrumbs>
        <div style={{ flexGrow: 1 }} />
        {actions && actions.map(a => <HeaderActionButton key={a.label}  {...a} />)}
      </Box>
      <Divider />
    </>
  )
}

export default ContentWithHeader;
