import { Cancel } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { SubscriptionDto } from '../../common/models/subscrioption_dto';

interface SubscriptionDataGridProps {
    subscriptions: SubscriptionDto[];
    onDelete: (uuid: string) => void;
}

const SubscriptionDataGrid: React.FC<SubscriptionDataGridProps> = ({ subscriptions, onDelete }) => {
    const columns: GridColDef[] = [
        { field: 'usrDest', headerName: 'Username', flex: 1 },
        { field: 'connDestId', headerName: 'Node ID', flex: 1 },
        { field: 'subscriptionStatus', headerName: 'Status', flex: 1 },
        {
            field: 'actions',
            headerName: '',
            align: 'center',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams<SubscriptionDto>) => {
                return (
                    <Button startIcon={<Cancel />} color="warning" onClick={(event) => onDelete(params.row.subscriptionUuid)}>
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <DataGrid rows={subscriptions} columns={columns} getRowId={(row)=> row.subscriptionUuid}  paginationMode="client" hideFooterPagination />
        </div>
    );
};

export default SubscriptionDataGrid;
