import axios from 'axios';
import { NodeDto } from '../models/node_dto';
import { QueryClient } from '@tanstack/react-query';
import { ResourceDto } from '../models/resource_dto';
import { ResourceCatalogDto } from '../models/resource_catalog_dto';
import { AccessGrantRequestDto } from '../models/access_grant_request_dto';
import { AccessGrantResponseDto } from '../models/access_grant_response_dto';
import { AccessGrantDto, GrantStatus } from '../models/access_grant_dto';
import { SubscriptionDto } from '../models/subscrioption_dto';
import { CommSummaryTotalsDto } from '../models/comm_summary_totals_dto';
import { MessageEnvelope } from '../models/message_dto';

const BASE_PATH = '/api/connector'

// These need to be converted to use React Query and Axios libs.  Until then, 
// they need the full BASE_PATH embedded in the URI path.
export const AUTH_CODE_URI_PATH = `${BASE_PATH}/auth/code`
export const AUTH_CONFIG_URI_PATH = `${BASE_PATH}/auth/config`

/**
 * The URI paths below are relative to the BASE_PATH above.
 * 
 * This works because the axiosInstance is created with a baseURL set to the BASE_PATH.
 * So it automatically expects all paths given to it for HTTP requests to be relative
 * to this baseURL.
 */
export const NODE_URI_PATH = `/node`
export const NODE_SELF_URI_PATH = `${NODE_URI_PATH}/self`

export const RESOURCE_URI_PATH = '/resource'
export const RESOURCE_ACCESS_URI_PATH = `${RESOURCE_URI_PATH}/access`

export const DATA_EXCHANGE_URI_PATH = '/data-exchange'
export const DATA_EXCHANGE_ACCESS_URI_PATH = `${DATA_EXCHANGE_URI_PATH}/access`
export const DATA_EXCHANGE_CATALOG_URI_PATH = `${DATA_EXCHANGE_URI_PATH}/catalog`
export const DATA_EXCHANGE_LOCAL_URI_PATH = `${DATA_EXCHANGE_URI_PATH}/local`

export const SUBSCRIPTION_URI_PATH = `${RESOURCE_URI_PATH}/subscription`
export const SUBSCRIPTION_TO_SEND_URI_PATH = `${SUBSCRIPTION_URI_PATH}/to-send`

export const COMM_SUMMARY_URI_PATH = '/comm-summary'
export const COMM_SUMMARY_TOTALS_URI_PATH = `${COMM_SUMMARY_URI_PATH}/totals`

export const queryClient = new QueryClient()

const axiosInstance = axios.create({
  baseURL: BASE_PATH,
});

// Add a request interceptor that sends our JWT with every request
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenStr = localStorage.getItem('jwt') || '';
    const token = JSON.parse(tokenStr)
    if (token) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor that redirects to the home page when we are not logged in
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through any successful response
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to the home page if a 401 response is received
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);


export const getNodes = async () => {
  const response = await axiosInstance.get<NodeDto[]>(NODE_URI_PATH)
  return response.data
}

export const getNodeSelf = async () => {
  const response = await axiosInstance.get<NodeDto>(NODE_SELF_URI_PATH)
  return response.data
}

export const getLocalResources = async () => {
  const response = await axiosInstance.get<ResourceDto[]>(RESOURCE_URI_PATH)
  return response.data
}

export const updateLocalResource = async (resource: ResourceDto) => {
  const path = `${RESOURCE_URI_PATH}/${resource.uuid}`
  const response = await axiosInstance.put<ResourceDto>(path, resource)
  return response.data
}

export const createLocalResource = async (resource: ResourceDto) => {
  const response = await axiosInstance.post<ResourceDto>(RESOURCE_URI_PATH, resource)
  return response.data
}

export const deleteLocalResource = async (uuid: string) => {
  const path = `${RESOURCE_URI_PATH}/${uuid}`
  await axiosInstance.delete(path)
}

export const getLocalResourceAccessInfo = async () => {
  const response = await axiosInstance.get<AccessGrantDto[]>(RESOURCE_ACCESS_URI_PATH)
  return response.data
}

export const updateLocalResourceAccessInfo = async (id: number, grantStatus: GrantStatus) => {
  const response = await axiosInstance.put<AccessGrantDto>(`${RESOURCE_ACCESS_URI_PATH}/${id}`, { grantStatus })
  return response.data
}

export const deleteLocalResourceAccessInfo = async (id: number) => {
   await axiosInstance.delete(`${RESOURCE_ACCESS_URI_PATH}/${id}`)
}

export const getResourceCatalogForNode = async (nodeId: string) => {
  const params = { nodeId };
  const response = await axiosInstance.get<ResourceCatalogDto>(DATA_EXCHANGE_CATALOG_URI_PATH, { params })
  return response.data
}

export const postGrantRequest = async (req: AccessGrantRequestDto) => {
  const response = await axiosInstance.post<AccessGrantResponseDto>(DATA_EXCHANGE_ACCESS_URI_PATH, req)
  return response.data
}

export const postExchangeMessage = async (message: MessageEnvelope) => {
  const response = await axiosInstance.post<MessageEnvelope>(DATA_EXCHANGE_LOCAL_URI_PATH, message)
  return response.data
}

export const getLocalResourceSubscriptions = async () => {
  const response = await axiosInstance.get<SubscriptionDto[]>(SUBSCRIPTION_TO_SEND_URI_PATH)
  return response.data
}

export const deleteLocalResourceSubscription = async (uuid: string) => {
  await axiosInstance.delete(`${SUBSCRIPTION_TO_SEND_URI_PATH}/${uuid}`)
}

export const getCommSummaryTotals = async () => {
  const response = await axiosInstance.get<CommSummaryTotalsDto>(COMM_SUMMARY_TOTALS_URI_PATH)
  return response.data
}

