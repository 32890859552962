import { Lock } from '@mui/icons-material';
import Button from '@mui/material/Button';
import React from 'react';
import { useAccessGrantRequestQuery } from '../../common/hooks/use_access_grant_request_query';
import { GrantStatus } from '../../common/models/access_grant_dto';

interface RequestAccessButtonProps {
    nodeId: string
    resourceUuid: string
    grantStatus: GrantStatus
    onRefreshNeeded: () => void
}

const RequestAccessButton: React.FC<RequestAccessButtonProps> = ({ nodeId, resourceUuid, grantStatus, onRefreshNeeded }: RequestAccessButtonProps) => {
    const mutation = useAccessGrantRequestQuery();
    const { label, isVisible, isDisabled } = useAccessButtonState(grantStatus)

    if (!isVisible) {
        return null
    }

    const handleClick = (e: React.FormEvent) => {
        e.preventDefault();
        mutation.mutate({ nodeId, resourceUuid }, { onSuccess: onRefreshNeeded});
    };

    return (
        <Button
            variant='outlined'
            color='success'
            startIcon={<Lock />}
            disabled={isDisabled || mutation.isPending}
            onClick={handleClick}
            sx={{ marginLeft: 8, marginBottom: 0.5 }}
        >

            {mutation.isPending ? 'Requesting Access...' : label}
        </Button>
    );
};

interface AccessButtonState {
    isVisible: boolean
    isDisabled: boolean
    label: string
}

function useAccessButtonState(grantStatus: GrantStatus): AccessButtonState {
    console.log('useAccessButton: grantStatus is', grantStatus)
    switch (grantStatus) {
        case 'PENDING':
            return { label: 'Resend Access Request', isVisible: true, isDisabled: false }

        case 'DENIED_NODE':
        case 'DENIED_USER':
        case 'GRANTED_NODE':
        case 'GRANTED_USER':
            return { label: '', isVisible: false, isDisabled: true }

        default:
            return { label: 'Request Access', isVisible: true, isDisabled: false }
    }
}

export default RequestAccessButton;
