import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResourceCatalogDto } from '../models/resource_catalog_dto';
import { postGrantRequest } from '../services/query_service';
import { getResourceCatalogQueryKeyForNode } from './use_resource_catalog_query';

/**
 * Send an access grant request to the remote node.
 */
export function useAccessGrantRequestQuery() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: postGrantRequest,

        // Optimistically update the cache before the mutation resolves
        onMutate: async (grantRequest) => {
            const queryKey = getResourceCatalogQueryKeyForNode(grantRequest.nodeId)

            // Cancel any outgoing refetches (so they don’t overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous value
            const currCatalog = queryClient.getQueryData<ResourceCatalogDto>(queryKey);

            // Update the current resource's grant status to PENDING
            const resource = currCatalog?.resourceList.find(r => r.uuid === grantRequest.resourceUuid)
            if (resource) {
                console.log('Setting resource', resource.uuid, 'grant status to PENDING')
                resource.grantStatus = 'PENDING'
                queryClient.setQueryData(queryKey, currCatalog);
            }

            // Return a rollback function in case of error
            return { prevCatalog: currCatalog };
        },

        // If the mutation fails, rollback the cache to the previous value
        onError: (err, accessGrant, context) => {
            console.error('Error during grant request:', err)
            if (context?.prevCatalog) {
                const queryKey = getResourceCatalogQueryKeyForNode(accessGrant.nodeId);
                queryClient.setQueryData(queryKey, context.prevCatalog);
            }
        },

        // After a successful mutation, invalidate the query to refetch fresh data
        onSuccess: (resp, req, context) => {
            console.log('Got access grant response:', resp)
            const queryKey = getResourceCatalogQueryKeyForNode(req.nodeId)
            queryClient.invalidateQueries({ queryKey })
            const resource = context?.prevCatalog?.resourceList.find(r => r.uuid === resp.resourceUuid)
            if (resource) {
                resource.grantStatus = resp.grantStatus
                queryClient.invalidateQueries({ queryKey })
            }
        },
    });
}
