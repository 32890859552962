import { Box, Paper, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { BusyMessage, ErrorMessage } from "../common/components/messages"
import { LOCAL_RESOURCES_ROUTE } from "../common/config/client_routes"
import { useDeleteSubscription } from "../common/hooks/use_subscription_query"
import CenterOnPage from "../common/layouts/CenterOnPage"
import SideNavLayout from "../common/layouts/SideNavLayout"
import { titleCase } from "../common/utils/string_fns"
import { buildLocalResourceRoute } from "./common/local_resource_routes"
import SubscriptionDataGrid from "./common/SubscriptionDataGrid"
import { useLocalResourceQueries } from "./common/use_local_resource_grant_queries"

interface LocalResourceViewParams {
  [param: string]: string | undefined
}

const SubscriptionView = () => {
  const deleteMutation = useDeleteSubscription()

  const { resourceId } = useParams<LocalResourceViewParams>()
  const { resources, subscriptions, isPending, isError } = useLocalResourceQueries()

  console.log('subscriptions', subscriptions)

  let content = null
  const resource = resources?.find(r => r.uuid === resourceId)
  const filterdSubs = subscriptions?.filter(sub => sub.resourceUuid === resourceId)

  if (isPending) {
    content = (
      <CenterOnPage>
        <BusyMessage message="Loading Resources..." />
      </CenterOnPage>
    )
  } else if (isError) {
    content = (
      <CenterOnPage>
        <ErrorMessage type="Network Error" message="Could not retrieve the resource list." />
      </CenterOnPage>
    )
  } else {
    const handleDelete = (uuid: string) => {
      deleteMutation.mutate(uuid)
    }

    content = (
      <Box px={4} py={2} height="100%">
        <Paper sx={{ height: "100%" }}>
          <Stack height="100%" flexDirection="column" px={4} py={2}>
            <Typography variant="h5">Active Subscriptions</Typography>
            <Box height={8} />
            <SubscriptionDataGrid
              subscriptions={filterdSubs || []}
              onDelete={handleDelete}
            />
          </Stack>
        </Paper>
      </Box>
    )
  }

  const breadcrumbs = [
    { label: 'Local Resources', href: LOCAL_RESOURCES_ROUTE },
    { label: titleCase(resource?.name || ''), href: buildLocalResourceRoute(resourceId || '') },
    { label: 'Subscriptions' }
  ]

  return (
    <SideNavLayout>
      <ContentWithHeader
        title="View Subscriptions"
        breadcrumbs={breadcrumbs}
        children={content}
      />
    </SideNavLayout>
  )
}

export default SubscriptionView


/* Test code - feel free to delete.
function generateSubscriptionMessages(resourceUuid: string): SubscriptionDto[] {
  const subscriptionStatuses: SubscriptionDto['subscriptionStatus'][] = [
    'REQUEST_START', 'REQUEST_STOP', 'INITIATED', 'TERMINATED', 'ERROR', 'DATA_RECORD'
  ];

  return Array.from({ length: 10 }, (_, index) => {
    const useProvidedUuid = index < 5;
    return {
      connDestId: `destNode${index + 1}`,
      resourceUuid: useProvidedUuid ? resourceUuid : `${index + 1}fa85f64-5717-4562-b3fc-2c963f66afa6`,
      subscriptionStatus: subscriptionStatuses[index % subscriptionStatuses.length],
      subscriptionUuid: `${index + 1}fa85f64-5717-4562-b3fc-2c963f66afa6`,
      usrDest: `user${index + 1}`
    };
  });
}
*/