import { Edit } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'

interface TitleWithEditButtonProps {
    children: ReactNode
    onEdit?: () => void
}

const TitleWithEditButton = ({ onEdit, children }: TitleWithEditButtonProps) => (
    <Stack direction="row" justifyContent="space-between" alignItems="start">
        {children}
        {onEdit &&
            <IconButton onClick={onEdit}>
                <Edit />
            </IconButton>
        }
    </Stack>
)

export default TitleWithEditButton