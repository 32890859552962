import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'

interface CopyToClipboardButtonProps {
  text: string
  iconSize?: number
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ text, iconSize }) => {
  const [open, setOpen] = useState(false)

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    navigator.clipboard.writeText(text)
      .then(() => {
        setOpen(true)
      })
      .catch((err) => {
        console.error('Failed to copy UUID text to clipboard: ', err)
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Copy UUID to Clipboard">
        <IconButton onClick={handleCopy} size='small'>
          <ContentCopyIcon sx={{ width: iconSize || 16 }} />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        message="Copied to clipboard!"
      />
    </>
  )
}

export default CopyToClipboardButton