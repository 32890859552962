import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MessageEnvelope } from "../models/message_dto";
import { postExchangeMessage } from "../services/query_service";

const queryKey = ['data-exchange', 'local']

export function useDataRequestQuery() {
    const queryClient = useQueryClient();

    return useMutation<MessageEnvelope, Error, MessageEnvelope>({
        mutationFn: postExchangeMessage,

        onSuccess: () => {
            console.log('Successfully recieved data from exchange')
            queryClient.invalidateQueries({ queryKey });
        },

        onError: (error) => {
            console.error('Error sending data request:', error);
        },
    });
}
