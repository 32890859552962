import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { CenteredBusyMessage, CenteredErrorMessage } from "../common/components/messages"
import { RemoteResourceCatalog } from "../common/components/ResourceCatalog"
import { REMOTE_CATALOGS_ROUTE } from "../common/config/client_routes"
import { useResourceCatalogQuery } from "../common/hooks/use_resource_catalog_query"
import CenterOnPage from "../common/layouts/CenterOnPage"
import SideNavLayout from "../common/layouts/SideNavLayout"

interface RemoteCatalogViewParams {
    [id: string]: string | undefined
}

const RemoteCatalogView = () => {
    // Extract the nodeId parameter from the URL
    const { nodeId } = useParams<RemoteCatalogViewParams>()

    const { data: catalog, isPending: isCatalogPending, isError: isCatalogError } = useResourceCatalogQuery(nodeId || '')

    const breadcrumbs = [
        { label: `Catalogs`, href: REMOTE_CATALOGS_ROUTE },
        { label: `${nodeId}` },
    ]

    let content = null

    if (isCatalogPending) {
        content = <CenteredBusyMessage message="Fetching resource catalog..." />
    } else if (isCatalogError) {
        content = <CenteredErrorMessage type="Network Error" message="Could not retrieve the resource catalog." />
    } else {
        content = (
            <CenterOnPage>
                <Box px={4} py={2} height="100%" width="100%">
                    <RemoteResourceCatalog resources={catalog!.resourceList} />
                </Box>
            </CenterOnPage>
        )
    }

    return (
        <SideNavLayout>
            <ContentWithHeader
                title={`${nodeId} Catalog`}
                breadcrumbs={breadcrumbs}
                children={content}
            />
        </SideNavLayout>
    )
}

export default RemoteCatalogView
