import { useEffect, useRef } from "react";
import { ForceGraph3D } from "react-force-graph";

// Define interfaces for graph data structures
interface GraphNode {
    id: number;
}

interface GraphLink {
    source: number;
    target: number;
}

interface GraphData {
    nodes: GraphNode[];
    links: GraphLink[];
}

function getRandomId(id: number): number {
    return Math.round(Math.random() * (id - 1));
}

// Generate random tree with proper typing
function genRandomTree(N: number = 300, reverse: boolean = false): GraphData {
    const nodes: GraphNode[] = [...Array(N).keys()].map(i => ({ id: i }));

    const links: GraphLink[] = [...Array(N).keys()]
        .filter(id => id)
        .map(id => ({
            source: reverse ? getRandomId(id) : id,
            target: reverse ? id : getRandomId(id)
        }));

    return { nodes, links };
}

const data: GraphData = genRandomTree();
const distance: number = 1000;

const NodeGraph = () => {
    // Use a more general type for the ref
    const fgRef = useRef<any>();

    useEffect(() => {
        const fg = fgRef.current;
        if (!fg) return;

        // Set initial camera position
        fg.cameraPosition({ z: distance });

        // Camera orbit
        let angle = 0;
        const interval = setInterval(() => {
            if (!fg) return;

            fg.cameraPosition({
                x: distance * Math.sin(angle),
                z: distance * Math.cos(angle)
            });

            angle += Math.PI / 5000;
        }, 10);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,                                   // Place behind other content
            pointerEvents: 'none'                         // Ignore mouse interactions
        }}>
            <ForceGraph3D
                ref={fgRef}
                graphData={data}
                enableNodeDrag={false}
                enableNavigationControls={false}
                showNavInfo={false}
                backgroundColor="#fff"
                nodeColor={() => "rgb(64, 128, 255)"}      // Blue for nodes
                nodeOpacity={0.4}                         // Translucent nodes
                nodeResolution={10}                       // Lower polygon count for softer look
                linkColor={() => "rgb(160, 160, 200)"}    // Bluegrey for links
                linkOpacity={0.2}                         // Additional link transparency
                linkWidth={3}                             // Thin links
                linkResolution={3}                        // Lower polygon count for links
                cooldownTime={1000}                       // Slower physics for gentler movement
                width={window.innerWidth}                 // Full width
                height={window.innerHeight}               // Full height
            />
        </div>
    );
};

export default NodeGraph;