/**
 * Formats server uptime into a human-readable string with days and decimal hours
 * @param startTime Unix timestamp in milliseconds when the server started
 * @returns Formatted string like "2 days 4.5 hours"
 * 
 * @example
 *   const uptime = formatUptime(serverStartTime);
 *   Returns: "2 days, 4.5 hours"
 */
export function formatUptime(startTime: number): string {
    if (!Number.isInteger(startTime)) {
        throw new Error('Start time must be an integer timestamp');
    }
    
    const now = Date.now();
    if (startTime > now) {
        throw new Error('Start time cannot be in the future');
    }
    
    const ms = now - startTime;
    const seconds = ms / 1000;
    const totalHours = seconds / 3600;
    const days = Math.floor(totalHours / 24);
    const hours = +(totalHours % 24).toFixed(1); // Round to 1 decimal place
    
    const parts: string[] = [];
    
    if (days > 0) {
        parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
    }
    if (hours > 0) {
        parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
    }
    
    return parts.join(', ') || '0 hours';
}