import { Lock, LockOpen, SmartDisplayOutlined, Subscriptions } from '@mui/icons-material';
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import { styled } from "@mui/material/styles";
import { isAccessGranted } from '../models/access_grant_dto';
import { ResourceDto } from "../models/resource_dto";
import { titleCase } from '../utils/string_fns';

interface ResourceChipRowProps {
    resource: ResourceDto
}

const ResourceChipRow = ({ resource }: ResourceChipRowProps) => {
    return (
        <Box mt={3} sx={{ display: 'flex', flexWrap: "wrap" }}>
            <AccessChip resource={resource} />
            <TypeChip resource={resource} />
            <div style={{ flexGrow: 1 }} />
            <VersionChip resource={resource} />
        </Box>
    )
}

interface ResourceChipProps {
    resource: ResourceDto
}

const ResourceChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const AccessChip = ({ resource }: ResourceChipProps) => {
    const label = titleCase(resource.permissions)
    const icon = isAccessGranted(resource.grantStatus) || resource.permissions === 'PUBLIC' ? <LockOpen /> : <Lock />
    const color = isAccessGranted(resource.grantStatus) || resource.permissions === 'PUBLIC' ? 'success' : 'warning'

    return <ResourceChip size="small" label={label} color={color} icon={icon} />
}

const TypeChip = ({ resource }: ResourceChipProps) => {
    const label = titleCase(resource.type)

    switch (resource.type) {
        case 'DATA':
            return <ResourceChip size="small" icon={<SmartDisplayOutlined />} label={label} color="primary" />
        case 'SERVICE':
            return <ResourceChip size="small" icon={<Subscriptions />} label={label} color="secondary" />
    }
}

const VersionChip = ({ resource }: ResourceChipProps) => {
    return <ResourceChip size="small" label={`Version ${resource.version}`} />
}

export default ResourceChipRow