/**
 * Represents a node in a federated data network.
 * 
 * Fields:
 * - clientId: Keycloak client ID, unused by the browser and only temporary pending mTLS.
 * - host: The hostname or IP address of the local node.
 * - name: The human-readable name of the local node.
 * - nodeId: A unique identifier for the local node within the federated network.
 */
export interface NodeDto {
    clientId: string;
    host: string;
    name: string;
    nodeId: string;
    description?: string;
}

export const defaultNodeDto: NodeDto = {
    clientId: '',
    host: '',
    name: '',
    nodeId: '',
    description: ''
}