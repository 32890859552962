import { Delete, InfoRounded, Key, Subscriptions } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '../../common/components/ConfirmDialog'
import ResourceDocsSection from '../../common/components/ResourceDocsSection'
import ResourceUuid from '../../common/components/ResourceUuid'
import { CenteredBusyMessage } from '../../common/components/messages'
import { LOCAL_RESOURCES_ROUTE } from '../../common/config/client_routes'
import { useDeleteResource } from '../../common/hooks/use_resource_query'
import { AccessGrantDto, countPending } from '../../common/models/access_grant_dto'
import { isResourcePublic, ResourceDto } from '../../common/models/resource_dto'
import { SubscriptionDto } from '../../common/models/subscrioption_dto'
import { dumbPluralize, safeDescription, titleCase } from '../../common/utils/string_fns'
import TitleWithEditButton from './TitleWithEditButton'
import { ACCESS_ROUTE, EDIT_REQUEST_PARAMS_ROUTE, EDIT_REQUEST_ROUTE, EDIT_RESOURCE_ROUTE, EDIT_RESPONSE_ROUTE, SUBSCRIPITION_ROUTE } from './local_resource_routes'

interface LocalResourceDetailsProps {
    resource: ResourceDto
    grants: AccessGrantDto[]
    subscriptions: SubscriptionDto[]
}

const LocalResourceDetails = ({ resource, grants, subscriptions }: LocalResourceDetailsProps) => {
    const navigate = useNavigate()
    const deleteMutation = useDeleteResource()

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleEditResource = () => navigate(EDIT_RESOURCE_ROUTE)
    const handleEditRequest = () => navigate(EDIT_REQUEST_ROUTE)
    const handleEditResponse = () => navigate(EDIT_RESPONSE_ROUTE)
    const handleEditDefaultBody = () => navigate(EDIT_REQUEST_PARAMS_ROUTE)
    const handleAccessGrantClick = () => navigate(ACCESS_ROUTE)
    const handleSubscirptionClick = () => navigate(SUBSCRIPITION_ROUTE)

    const handleDelete = () => setDialogOpen(true)
    const handleDeleteConfirmed = (confirm: boolean) => {
        setDialogOpen(false)
        if (confirm) {
            deleteMutation.mutate(resource.uuid, { onSuccess: () => navigate(LOCAL_RESOURCES_ROUTE) })
        }
    }

    if (deleteMutation.isPending) {
        return <CenteredBusyMessage message='Deleting resource from catalog...' />
    }

    return (
        <Paper elevation={1}>
            <Box px={2} py={2}>
                <TitleWithEditButton onEdit={handleEditResource}>
                    <Stack>
                        <Typography variant="h5">{titleCase(resource.name)}</Typography>
                        <ResourceUuid uuid={resource.uuid} />
                    </Stack>
                </TitleWithEditButton>

                <ResourceDetailItem
                    text={safeDescription(resource?.description)}
                    label='Description'
                />

                <ResourceDetailItem
                    text={resource.version.toString()}
                    label='Version'
                />

                <ResourceDetailItem
                    text={titleCase(resource.type)}
                    label='Type'
                    caption='Data resources reply with a one-time response. Service resources allow you to subscribe for multiple updates.'
                />

                <ResourceDetailItem
                    text={titleCase(resource.permissions)}
                    label='Permission'
                    caption='Resources can either be Public or Restricted.  Users must be granted access to Restricted resources.'
                />

                {!isResourcePublic(resource) && resource.grantStatus &&
                    <Box sx={{ display: 'flex', justifyContent: "start", alignItems: "end" }}>
                        <ResourceDetailItem
                            text={titleCase(resource.grantStatus)}
                            label='Grant Status'
                            caption='The status of any access you have been granted to this resource.'
                        />
                    </Box>
                }
            </Box>

            <AccessAndSubscriptionSection
                resource={resource}
                grants={grants}
                subscriptions={subscriptions}
                onAccessGrantClick={handleAccessGrantClick}
                onSubscriptionClick={handleSubscirptionClick}
            />

            <Divider />
            <ResourceDocsSection docType={'exampleRequest'} resource={resource} onEdit={handleEditRequest} />

            <Divider />
            <ResourceDocsSection docType={'defaultRequestBody'} resource={resource} onEdit={handleEditDefaultBody} />

            <Divider />
            <ResourceDocsSection docType={'exampleResponse'} resource={resource} onEdit={handleEditResponse} />

            <Divider />
            <Stack px={1} py={2} alignItems="center">
                <Button size="large" variant="contained" color="error" startIcon={<Delete />} onClick={handleDelete}>
                    Delete This Resource From The Catalog
                </Button>
            </Stack>

            <ConfirmDialog
                title='Confirm Permanent Removal'
                message="This will permanently remove this resource from the resource catalog.  Are you sure?"
                open={dialogOpen}
                onClose={handleDeleteConfirmed}
            />
        </Paper>
    )
}

interface ResourceDetailItemProps {
    text: string
    label?: string
    caption?: string
    showBadge?: boolean
}

const tightSpacing = { margin: 0, lineHeight: 1, marginRight: 1, maxWidth: "60vw" };

const ResourceDetailItem = ({ text, label, caption, showBadge }: ResourceDetailItemProps) => (
    <Box mt={1} mb={1}>
        {label && <Typography variant='caption' color='textSecondary'>{label}</Typography>}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge variant="dot" color="error" badgeContent={showBadge ? 1 : 0} >
                <Typography variant='h6' sx={tightSpacing}>{text}</Typography>
            </Badge>
            {caption &&
                <Tooltip title={caption}>
                    <InfoRounded color='primary' sx={{ width: 20 }} />
                </Tooltip>
            }
        </Box>
    </Box>
)

interface AccessAndSubscriptionSectionProps {
    resource: ResourceDto
    grants: AccessGrantDto[]
    subscriptions: SubscriptionDto[]
    onAccessGrantClick: () => void
    onSubscriptionClick: () => void
}

const AccessAndSubscriptionSection = ({ resource, grants, subscriptions, onAccessGrantClick, onSubscriptionClick }: AccessAndSubscriptionSectionProps) => {
    if (resource.permissions !== 'RESTRICTED' && resource.type !== 'SERVICE') {
        return null
    }

    return (
        <>
            <Divider />
            <Box px={2} py={2}>
                {
                    resource.permissions === 'RESTRICTED' && (
                        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                            <ResourceDetailItem
                                text={`${countPending(grants)} Pending`}
                                label='Access Grants'
                                showBadge={countPending(grants) > 0}
                            />
                            <Button variant="outlined" startIcon={<Key />} onClick={onAccessGrantClick}>
                                View Access Grants
                            </Button>
                        </Stack>
                    )
                }

                {
                    resource.type === 'SERVICE' && (
                        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                            <ResourceDetailItem
                                text={dumbPluralize(subscriptions.length, 'Subscription')}
                                label='Subscriptions'
                            />
                            <Button variant="outlined" startIcon={<Subscriptions />} onClick={onSubscriptionClick}>
                                View Subscriptions
                            </Button>
                        </Stack>
                    )
                }
            </Box>
        </>
    )
}

export default LocalResourceDetails