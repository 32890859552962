import "easymde/dist/easymde.min.css"; // Import SimpleMDE CSS
import { useParams } from "react-router-dom";
import ContentWithHeader from "../common/components/ContentWithHeader";
import { BusyMessage, ErrorMessage, NotFoundError } from "../common/components/messages";
import { LOCAL_RESOURCES_ROUTE } from "../common/config/client_routes";
import { useResourceQuery } from "../common/hooks/use_resource_query";
import CenterOnPage from "../common/layouts/CenterOnPage";
import SideNavLayout from "../common/layouts/SideNavLayout";
import EditDocString, { getTitleStringForType, ResourceDocType } from "./common/EditDocString";
import { buildLocalResourceRoute } from "./common/local_resource_routes";
import { titleCase } from "../common/utils/string_fns";

interface LocalResourceViewParams {
    [param: string]: string | undefined
}

interface EditResourceDocsViewProps {
    docType: ResourceDocType
}

const EditResourceDocsView = ({ docType }: EditResourceDocsViewProps) => {
    const { resourceId } = useParams<LocalResourceViewParams>()
    const { data: resources, isPending: isResourceListPending, isError: isResourceListError } = useResourceQuery()

    const resource = resources?.find(r => r.uuid === resourceId)
    const resourceName = titleCase(resource?.name || 'Not Found')
    const resourceRoute = buildLocalResourceRoute(resourceId || '')
    
    let content = null

    if (isResourceListPending) {
        content = (
            <CenterOnPage>
                <BusyMessage message="Loading Resources..." />
            </CenterOnPage>
        )
    } else if (isResourceListError) {
        content = (
            <CenterOnPage>
                <ErrorMessage type="Network Error" message="Could not retrieve the resource." />
            </CenterOnPage>
        )
    } else if (!resource) {
        content = <NotFoundError message={`Resource ID "${resourceId}" was not found.`} />
    } else {
        content = <EditDocString title={getTitleStringForType(docType)} docType={docType} resource={resource}  />
    }

    const breadcrumbs = [
        { label: `Local Resources`, href: LOCAL_RESOURCES_ROUTE },
        { label: resourceName, href: resourceRoute },
        { label: getTitleStringForType(docType) }
    ]

    return (
        <SideNavLayout>
            <ContentWithHeader
                title={resourceName}
                breadcrumbs={breadcrumbs}
                children={content}
            />
        </SideNavLayout>
    )
}

export default EditResourceDocsView