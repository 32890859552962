import { MaybeString } from "../utils/string_fns"

/**
 * This file contains a collection of constant strings that define the routes for the React application.
 * 
 * These route constants help centralize and manage the application's routing paths in one place, 
 * improving maintainability and reducing the likelihood of errors when referring to routes throughout the app.
 * 
 * @example
 * // Example usage of a route in a React component
 * import { HOME_ROUTE, AUTH_CALLBACK_ROUTE } from './routes';
 * 
 * <Link to={HOME_ROUTE}>Home</Link>
 * <Link to={AUTH_CALLBACK_ROUTE}>Auth Callback</Link>
 * 
 * @exports
 * - HOME_ROUTE: string - Path for the home page
 * - AUTH_CALLBACK_ROUTE: string - Path for the OAuth2/OIDC authentication callback
 * - LOCAL_RESOURCES_ROUTE: string - Path for viewing local resources
 * - REMOTE_CATALOGS_ROUTE: string - Path for accessing remote catalogs
 */
export const HOME_ROUTE = '/'
export const AUTH_CALLBACK_ROUTE = '/callback'
export const LOCAL_RESOURCES_ROUTE = "/local_resources"
export const REMOTE_CATALOGS_ROUTE = "/remote_catalogs"

export const buildRoute = (baseUrl: MaybeString, id: string) => baseUrl ? `${baseUrl}/${id}` : id
export const buildRemoteCatalogRoute = (nodeId: string) => buildRoute(REMOTE_CATALOGS_ROUTE, nodeId)