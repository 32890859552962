import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import NodeGraph from "../common/components/NodeGraph";
import { useSession } from "../common/providers/SessionContext";
import FdspGlassPlate from './FdspGlassPlate';

export const NotAuthenticatedView = () => {
    const session = useSession()

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <NodeGraph />
            <FdspGlassPlate>
                <AuthenticateButton onClick={session.login} />
            </FdspGlassPlate>
        </div>
    )
}

interface AuthenticateButtonProps {
    onClick: () => void;
}

const AuthenticateButton: React.FC<AuthenticateButtonProps> = ({ onClick }) => {
    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: 'green',
                color: 'white',
                padding: '24px 36px',
                fontSize: '1rem',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'darkgreen',
                },
            }}
            size="large"
            startIcon={<LockIcon />}
            endIcon={<ArrowForwardIcon />}
            onClick={onClick}
        >
            <Typography variant='h5'>
                Authenticate with Local Identity Manager
            </Typography>
        </Button>
    );
};

export default NotAuthenticatedView