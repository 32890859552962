import { useState, useEffect } from 'react'

/**
 * Custom React hook to get the OIDC state from local storage.
 * 
 * This hook reads the OIDC state from local storage based on the provided key (state).
 * It handles errors by setting an error state if the value cannot be retrieved or parsed.
 * 
 * @param {string} state - The key to retrieve the OIDC state from local storage.
 * @returns {string} A string containing the OIDC code verifier.
 */
export const useOidcCodeVerifier = (state: string): string => {
    const [codeVerifier, setCodeVerifier] = useState<string>('')

    useEffect(() => {
        try {
            const oidcStr = window.localStorage.getItem(`oidc.${state}`)

            if (oidcStr === null) {
                throw new Error(`OIDC auth response is not in local storage for state "${state}"`)
            }

            const oidcState = JSON.parse(oidcStr)
            const cv = oidcState['code_verifier']
            
            if (cv === null) {
                throw new Error(`Invalid code_verifier in OIDC auth response for state "${state}"`)
            }

            setCodeVerifier(cv)
        } catch (err) {
            console.warn(`Warning: ${err instanceof Error ? err.message : err }`)
            setCodeVerifier('')
        }
    }, [state])

    return codeVerifier
}
