import React from 'react';
import { useParams } from 'react-router-dom';
import ContentWithHeader from '../common/components/ContentWithHeader';
import { CenteredBusyMessage, CenteredErrorMessage } from '../common/components/messages';
import { buildRemoteCatalogRoute, REMOTE_CATALOGS_ROUTE } from '../common/config/client_routes';
import { useResourceCatalogQuery } from '../common/hooks/use_resource_catalog_query';
import SideNavLayout from '../common/layouts/SideNavLayout';
import { ResourceDetails } from './components/ResourceDetails';
import { titleCase } from '../common/utils/string_fns';

interface ResourceViewParams {
    [param: string]: string | undefined
}

const ResourceDetailsView: React.FC = () => {
    // Extract the parameters from the URL
    const { nodeId, resourceId } = useParams<ResourceViewParams>()

    const { data: catalog, isPending: isCatalogPending, isError: isCatalogError } = useResourceCatalogQuery(nodeId || '')

    const resource = catalog?.resourceList.find(r => r.uuid === resourceId)
    const resourceName = titleCase(resource?.name || resourceId || '')

    const breadcrumbs = [
        { label: `Catalogs`, href: REMOTE_CATALOGS_ROUTE },
        { label: `${nodeId}`, href: buildRemoteCatalogRoute(nodeId || '') },
        { label: resourceName }
    ]

    let content = null

    if (isCatalogPending) {
        content = <CenteredBusyMessage message="Fetching resource description..." />
    } else if (isCatalogError) {
        content = <CenteredErrorMessage type="Network Error" message="Could not retrieve the resource description." />
    } else {
        content = resource
            ? <ResourceDetails resource={resource} name={resourceName} nodeId={nodeId || ''} />
            : <CenteredErrorMessage type='Resource Error' message='Resource not found.' />
    }

    return (
        <SideNavLayout>
            <ContentWithHeader
                title={`Resource Details`}
                breadcrumbs={breadcrumbs}
                children={content}
            />
        </SideNavLayout>
    );
}

export default ResourceDetailsView;
