import { Button, Typography } from '@mui/material'
import { ReactNode } from 'react'

export interface HeaderActionButtonProps {
    label: string
    icon: ReactNode
    onClick: () => void  
}

const HeaderActionButton = ({label, icon, onClick}: HeaderActionButtonProps) => {
  return (
    <Button variant="contained" onClick={onClick} startIcon={icon} size="small">
        <Typography>{label}</Typography>
    </Button>
  )
}

export default HeaderActionButton