import Stack from "@mui/material/Stack";
import { useRef } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import CancelSaveButtonRow from "./CancelSaveButtonRow";

interface MarkdownEditorProps {
    content: string
    isSaving: boolean
    onSave: (content: string) => void
    onCancel: () => void
}

const MarkdownEditor = ({ content, isSaving, onSave, onCancel }: MarkdownEditorProps) => {
    const valueRef = useRef<string>(content);

    const handleChange = (value: string) => {
        valueRef.current = value
    };

    const handleSave = () => onSave(valueRef.current)

    return (
        <Stack sx={{ height: '100%' }}>
            <SimpleMdeReact
                value={valueRef.current}
                onChange={handleChange}
                options={{
                    autofocus: true,
                    minHeight: "500px",
                    maxHeight: "500px",
                    spellChecker: false, // Optional: disable spell checker
                    toolbar: [
                        'bold', 'italic', 'heading', '|', // Basic formatting options
                        'quote', 'unordered-list', 'ordered-list', '|',
                        'preview', 'guide' // Keep only essential options
                    ]
                }}
            />
            <CancelSaveButtonRow onSave={handleSave} onCancel={onCancel} isSaving={isSaving} />
        </Stack>
    );
};

export default MarkdownEditor;

