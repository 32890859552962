import { CloudUpload } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/adventure_time.css'; // Optional: pre-built themes
import { useDataRequestQuery } from '../../common/hooks/use_data_request_query'
import { isAccessGranted } from '../../common/models/access_grant_dto'
import { MessageEnvelope } from '../../common/models/message_dto'
import { ResourceDto } from '../../common/models/resource_dto'

interface DataRequestPaneProps {
    nodeId: string
    resource: ResourceDto
}

const DataRequestPane = ({ nodeId, resource }: DataRequestPaneProps) => {
    const [msgBody, setMsgBody] = useState<string>('')

    const mutation = useDataRequestQuery()
    const canRequestData = isAccessGranted(resource.grantStatus) || resource.permissions === 'PUBLIC'

    const handleRequestData = () => {
        console.log('got request data click')
        const envelope: MessageEnvelope = {
            connDestId: nodeId,
            connDestResourceUuid: resource.uuid,
            msgType: 'SERVICE_REQUEST',
            msgContent: {
                msgStandard: 'JSON',
                msgBody: resource.defaultRequestBody
            }
        }
        mutation.mutate(envelope, {
            onSuccess(data) {
                console.log('got response', data)
                setMsgBody(data.msgContent.msgBody)
            },
        })
    }

    return (
        <>
            <Divider />
            <Stack px={1} py={2} alignItems="center" flexDirection="column">
                <Button
                    size="large"
                    color='primary'
                    variant='contained'
                    startIcon={<CloudUpload />}
                    disabled={!canRequestData}
                    onClick={handleRequestData}
                >
                    Request Data from Remote Resource
                </Button>

                {msgBody &&
                    <JSONPretty
                        data={msgBody}
                        style={{ alignSelf: "stretch" }}
                        mainStyle="padding:1em;line-height:1.3;color:#46A;background:#F4F4F4;overflow:auto;borderRadius:24px"
                        keyStyle="color:#A62"
                        valueStyle="color:#A4A"
                        stringStyle="color:#4AA"
                        booleanStyle="color:#AA4"
                    />
                }
            </Stack>
        </>)
}

export default DataRequestPane