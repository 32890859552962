import Typography from "@mui/material/Typography"
import ScaleLoader from "react-spinners/ScaleLoader"

interface ConnectorBusySpinnerProps {
    label: string
}

export const ConnectorBusySpinner = ({ label }: ConnectorBusySpinnerProps) => {
    return (
        <div style={{ display: 'flex' }}>
            <ScaleLoader
                color={'#64B5F6'}
                loading={true}
                aria-label="Busy Spinner"
                data-testid="loader"
            />
            <div style={{ width: 20 }} />
            <Typography variant='h5' color="textSecondary" >{label}</Typography>
        </div>
    )
}