/**
 * Represents a summary of communication totals over a given period.
 *
 * Fields:
 * - commReceiveFailureTotals: Total number of failed received communications.
 * - commReceiveSuccessTotals: Total number of successfully received communications.
 * - commSendFailureTotals: Total number of failed sent communications.
 * - commSendSuccessTotals: Total number of successfully sent communications.
 * - startTimestamp: The timestamp indicating the start of the period for these totals.
 */
export interface CommSummaryTotalsDto {
    commReceiveFailureTotals: number;
    commReceiveSuccessTotals: number;
    commSendFailureTotals: number;
    commSendSuccessTotals: number;
    startTimestamp: number;
}

export const defaultCommSummaryTotals: CommSummaryTotalsDto = {
    commReceiveFailureTotals: 0,
    commReceiveSuccessTotals: 0,
    commSendFailureTotals: 0,
    commSendSuccessTotals: 0,
    startTimestamp: 0
}