import { useAccessGrantQuery } from "../../common/hooks/use_access_grant_query"
import { useNodeSelfQuery } from "../../common/hooks/use_node_self_query"
import { useResourceQuery } from "../../common/hooks/use_resource_query"
import { useSubscriptionQuery } from "../../common/hooks/use_subscription_query"
import { AccessGrantDto } from "../../common/models/access_grant_dto"
import { NodeDto } from "../../common/models/node_dto"
import { ResourceDto } from "../../common/models/resource_dto"
import { SubscriptionDto } from "../../common/models/subscrioption_dto"

export interface LocalResourceQuery {
    node: NodeDto | undefined
    resources: ResourceDto[] | undefined
    grants: AccessGrantDto[] | undefined
    subscriptions: SubscriptionDto[] | undefined
    isPending: boolean
    isError: boolean
}

export function useLocalResourceQueries(): LocalResourceQuery {
    const { data: node, isPending: isNodePending, isError: isNodeError } = useNodeSelfQuery()
    const { data: resources, isPending: isResourceListPending, isError: isResourceListError } = useResourceQuery()
    const { data: grants, isPending: isGrantListPending, isError: isGrantListError } = useAccessGrantQuery()
    const { data: subscriptions, isPending: isSubscriptionListPending, isError: isSubscriptionListError } = useSubscriptionQuery()

    const isPending = isNodePending || isResourceListPending || isGrantListPending || isSubscriptionListPending
    const isError = isNodeError || isResourceListError || isGrantListError || isSubscriptionListError

    return { node, resources, grants, subscriptions, isPending, isError }
}
