import { InsertChartOutlined } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Link } from 'react-router-dom'
import NodeActivityIcon from '../../common/assets/svg/node_activity.svg'
import { CommSummaryTotalsDto } from '../../common/models/comm_summary_totals_dto'
import DashboardGridCard from './DashboardGridCard'
import LabledItem from './LabeledItem'

interface NodeActivityCardProps {
    dashboardUrl: string
    commTotals: CommSummaryTotalsDto
}

const NodeActivityCard = ({ dashboardUrl, commTotals }: NodeActivityCardProps) => {
    const sentSummary = `${commTotals.commSendSuccessTotals} / ${commTotals.commSendFailureTotals}`
    const recvSummary = `${commTotals.commReceiveSuccessTotals} / ${commTotals.commReceiveFailureTotals}`

    return (
        <DashboardGridCard key="Activity" title="Activity" icon={NodeActivityIcon}>
            <Stack flexDirection="column" justifyContent="space-between" height="100%">
                <Stack marginBottom={2}>
                    <LabledItem text={sentSummary} label="Messages Sent / Errors" />
                    <LabledItem text={recvSummary} label="Messages Received / Errors" />
                </Stack>
                <Link to={dashboardUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" color="primary" startIcon={<InsertChartOutlined />}>
                        View Dashboard
                    </Button>
                </Link>
            </Stack>
        </DashboardGridCard>
    )
}

export default NodeActivityCard