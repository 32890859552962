import { buildRoute, LOCAL_RESOURCES_ROUTE } from "../../common/config/client_routes"
import { MaybeString } from "../../common/utils/string_fns"

export const ADD_RESOURCE_ROUTE = "add"
export const EDIT_RESOURCE_ROUTE = "edit"
export const EDIT_REQUEST_ROUTE = "edit/request"
export const EDIT_REQUEST_PARAMS_ROUTE = "edit/requestparams"
export const EDIT_RESPONSE_ROUTE = "edit/response"
export const ACCESS_ROUTE = "access"
export const SUBSCRIPITION_ROUTE = "subscription"

const maybeSeparator = (extraPath: MaybeString) => extraPath ? '/' : ''
const maybePath = (extraPath: MaybeString) => extraPath || ''

export const buildLocalResourceRoute = (resourceId: string, extraPath?: MaybeString) =>
    buildRoute(LOCAL_RESOURCES_ROUTE, resourceId + maybeSeparator(extraPath) + maybePath(extraPath))
