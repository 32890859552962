import { jwtDecode } from 'jwt-decode'
import { useEffect, useRef, useState } from 'react'
import { defaultTokenDto, TokenDto } from '../models/token_dto'
import { User } from '../models/user'
import { useLocalStorage } from './use_local_storage'
import { AUTH_CODE_URI_PATH } from '../services/query_service'

function parseUserFromJwt(jwt: string): User | null {
    if (!jwt) {
        return null
    }
    
    try {
        // Decode the token
        const decodedToken = jwtDecode<any>(jwt);

        // Check expiration
        const currentTime = Math.floor(Date.now() / 1000);
        const isExpired = decodedToken.exp && decodedToken.exp <= currentTime

        // Extract relevant user information
        const user: User = {
            jwt,
            isExpired,
            exp: decodedToken.exp,
            iat: decodedToken.iat,
            authTime: decodedToken.auth_time,
            jti: decodedToken.jti,
            iss: decodedToken.iss,
            aud: decodedToken.aud,
            sub: decodedToken.sub,
            sessionState: decodedToken.session_state,
            roles: decodedToken.realm_access?.roles || [],
            email: decodedToken.email,
            name: decodedToken.name,
            preferredUsername: decodedToken.preferred_username,
            givenName: decodedToken.given_name,
            familyName: decodedToken.family_name,
        };
        
        return user
    } catch (err) {
        return null;
    }
}
    
/**
 * Result type for the useTokenExchange hook.
 * 
 * Contains the token data, loading state, and any error information from the token exchange process.
 * 
 * @property {TokenDto} token - The JWT token data received from the authentication server
 * @property {boolean} isTokenLoading - Indicates whether a token exchange request is in progress
 * @property {Error | null} tokenError - Error information if the token exchange failed, null otherwise
 */
interface UseTokenExchangeResult {
    user: User | null
    clearToken: () => void
    isTokenLoading: boolean
    tokenError: Error | null
}

/**
 * Custom React hook to exchange an authorization code for a token.
 * 
 * This hook sends a POST request to the server to exchange the provided authorization
 * code, code verifier, and state for a JWT token. The token is stored in localStorage.
 * It also tracks the loading state and handles errors, propagating them to the component
 * for better control and user feedback.
 * 
 * @param {string} code - The authorization code received from the OIDC authorization server.
 * @param {string} state - The state parameter used to prevent CSRF attacks, should match the original request.
 * @param {string} codeVerifier - The original code verifier used to generate the code challenge for PKCE.
 * @param {string} redirectUri - The redirect URI that was used in the original authorization request.
 * 
 * @returns {UseTokenExchangeResult} An object containing:
 *   - token: The exchanged token data (JWT)
 *   - isTokenLoading: Boolean indicating if the token exchange is in progress
 *   - tokenError: Any error encountered during the process, or null if successful
 */
export function useTokenExchange(
    code: string,
    state: string,
    codeVerifier: string,
    redirectUri: string
): UseTokenExchangeResult {
    const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false)
    const [tokenError, setTokenError] = useState<Error | null>(null)
    const isMounted = useRef(true)

    const [token, setToken] = useLocalStorage<TokenDto>('jwt', defaultTokenDto)
    const user = parseUserFromJwt(token.access_token)

    // Ask for a new JWT if needed
    useEffect(() => {
        if (user !== null && !user.isExpired) {
            return
        }

        setIsTokenLoading(true)
        setTokenError(null)
        isMounted.current = true

        const fetchData = async () => {
            if (!state || !code || !codeVerifier || !redirectUri || !isMounted.current) {
                return
            }

            const body = { code, state, codeVerifier, redirectUri }

            try {
                const resp = await fetch(AUTH_CODE_URI_PATH, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: { 'Content-Type': 'application/json' }
                })

                if (!resp.ok) {
                    throw new Error(`Failed to fetch: ${resp.statusText}`)
                }

                const data = await resp.json()
                if (isMounted.current) {
                    console.log('setting data:', data)
                    setToken(data)
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setTokenError(err)
                } else {
                    setTokenError(new Error('An unknown error occurred'))
                }
            } finally {
                if (isMounted.current) {
                    setIsTokenLoading(false)
                }
            }
        }

        fetchData()

        return () => {
            isMounted.current = false // Prevent updates if the component is unmounted
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, state, codeVerifier, redirectUri, user])

    return {
        user,
        clearToken: () => setToken(defaultTokenDto),
        isTokenLoading,
        tokenError: tokenError
    }
}
