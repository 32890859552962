import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import React from 'react';

interface DashboardGridCardProps {
    key: string
    title: string
    icon: string
    children: React.ReactNode
}

const DashboardGridCard = ({ key, icon, title, children }: DashboardGridCardProps) => {
    return (
        <Grid size={{ xs: 12, md: 6, xl: 4 }} key={key}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardHeader
                    title={title}
                    titleTypographyProps={{ variant: 'h5' }}
                    avatar={<Avatar src={icon} alt={`${key} Icon`} sx={{ width: 38, height: 38 }} />}
                />
                <Divider />
                <CardContent children={children} sx={{ flex: 1 }} />
            </Card>
        </Grid>
    )
}

export default DashboardGridCard