import { Cancel, Save } from "@mui/icons-material"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

interface CancelSaveButtonRowProps {
    isSubmit?: boolean
    isSaving: boolean
    onSave?: () => void
    onCancel: () => void
}

const CancelSaveButtonRow = ({ isSubmit, isSaving, onSave, onCancel }: CancelSaveButtonRowProps) => {
    const saveLabel = isSaving ? 'Updating...' : 'Save'
    const saveBtnType = isSubmit ? 'submit' : 'button'

    return (
        <Stack py={1} spacing={1} useFlexGap direction="row" justifyContent="end">
            <Button disabled={isSaving} variant="outlined" startIcon={<Cancel />} onClick={onCancel}>Cancel</Button>
            <Button
                type={saveBtnType}
                disabled={isSaving}
                variant="contained"
                startIcon={<Save />}
                onClick={onSave}
            >
                {saveLabel}
            </Button>
        </Stack>
    )
}

export default CancelSaveButtonRow