import { Add } from "@mui/icons-material"
import { Box } from "@mui/material"
import { useNavigate } from "react-router-dom"
import ContentWithHeader from "../common/components/ContentWithHeader"
import { BusyMessage, ErrorMessage } from "../common/components/messages"
import { LocalResourceCatalog } from "../common/components/ResourceCatalog"
import CenterOnPage from "../common/layouts/CenterOnPage"
import SideNavLayout from "../common/layouts/SideNavLayout"
import { ADD_RESOURCE_ROUTE } from "./common/local_resource_routes"
import { useLocalResourceQueries } from "./common/use_local_resource_grant_queries"

const LocalCatalogView = () => {
    const navigate = useNavigate()
    const { node, resources, grants, isPending, isError } = useLocalResourceQueries()

    const actions = [
        { label: 'Add Resource', icon: <Add />, onClick: () => navigate(ADD_RESOURCE_ROUTE) }
    ]

    let content = null

    if (isPending) {
        content = (
            <CenterOnPage>
                <BusyMessage message="Loading Resources..." />
            </CenterOnPage>
        )
    } else if (isError) {
        content = (
            <CenterOnPage>
                <ErrorMessage type="Network Error" message="Could not retrieve the resource list." />
            </CenterOnPage>
        )
    } else {
        content = (
            <Box px={4} py={2} width="100%" height="100%">
                <LocalResourceCatalog resources={resources}  grants={grants}/>
            </Box>
        )
    }

    const rootBreadcrumbs = [
        { label: node?.name || '' }
    ]

    return (
        <SideNavLayout>
            <ContentWithHeader
                title="Resource List"
                breadcrumbs={rootBreadcrumbs}
                actions={actions}
                children={content}
            />
        </SideNavLayout>
    )
}

export default LocalCatalogView