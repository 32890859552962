import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ResourceDto } from "../models/resource_dto"
import { createLocalResource, deleteLocalResource, getLocalResources, updateLocalResource } from "../services/query_service"

const queryKey = ['resources']

export const useResourceQuery = () => {
    return useQuery({
        queryKey: queryKey,
        queryFn: async () => getLocalResources(),
        staleTime: 1000 * 60 * 5, // 5 minutes
    })
}

export function useUpdateResource() {
    const queryClient = useQueryClient();

    return useMutation<ResourceDto, Error, ResourceDto>({
        mutationFn: updateLocalResource,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
        onError: (error) => console.error('Error updating resource:', error)
    });
}

export function useCreateResource() {
    const queryClient = useQueryClient();

    return useMutation<ResourceDto, Error, ResourceDto>({
        mutationFn: createLocalResource,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
        onError: (error) => console.error('Error creating resource:', error)
    });
}

export function useDeleteResource() {
    const queryClient = useQueryClient();

    return useMutation<void, Error, string>({
        mutationFn: deleteLocalResource,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
            console.error('Error deleting resource:', error);
        },
    });
}