import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CopyToClipboardButton from "./CopyToClipboardButton";

interface ResourceUuidProps {
    uuid: string
}

const UuidRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: 0,
}));

const ResourceUuid = ({ uuid }: ResourceUuidProps) => {
    return (
        <UuidRow>
            <Typography variant="subtitle2" color="textSecondary">{uuid}</Typography>
            <CopyToClipboardButton text={uuid} />
        </UuidRow>
    )
}

export default ResourceUuid